import React, { useEffect, useLayoutEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
  Tabs,
  Tab,
  Popper,
  ClickAwayListener,
  Paper,
  Chip,
  TextField,
  InputAdornment,
} from "@mui/material";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import {
  useEditUserMutation,
  useGetPendingAgentQuery,
  useDeleteByIduserMutation,
  useLazyGetPendingAgentQuery,
} from "../../services/users";

import SearchBar2 from "../../components/SearchBar2";
import { isValidInput } from "../../utils/validation";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { errorToast, successToast } from "../../helpers/toast";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import Loader from "../../helpers/constants/Loader";
import { useLazyExportAgentCsvQuery } from "../../services/dashboard";
import { useLazyGetPerformanceInsightsQuery } from "../../services/performace";
import moment from "moment";
import { DayPicker } from "react-day-picker";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ReasonModal from "../../components/RejectReason";
import { getFromStorage, STORAGE_KEYS } from "../../helpers";
import { setCredentials } from "../../reducers/authSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import {
  getAgentCount,
  getCompanyCount,
  getCompanyRequestCount,
  getEnquriesCount,
  getFeedbackCount,
  getPaymentRequestCount,
  getPropertyRequestCount,
  setRequestCount,
} from "../../reducers/generalSlice";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageAgents = () => {
  const dispatch = useAppDispatch();

  const agentCount = useAppSelector(getAgentCount);
  const companyCount = useAppSelector(getCompanyCount);
  const companyRequestCount = useAppSelector(getCompanyRequestCount);
  const propertyRequestCount = useAppSelector(getPropertyRequestCount);
  const paymentRequestCount=useAppSelector(getPaymentRequestCount);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const insight = pathname?.includes("insights");
  const [searchParams, setSearchParams] = useSearchParams();
  let role = 5;
  const [rows1, setRows] = useState<any>([]);
  const [editUSERbyId] = useEditUserMutation();

  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount1] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [editCompanybyId] = useEditUserMutation();
  const [selectedId, setSelectedId] = useState<number>(0);
  const [selectedCompany, setSelectedCompany] = useState<string>("");

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };
  const [rejectionReason, setRejectionReason] = useState<string>("");
  const [openReject, setOpenReject] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [exportCsv] = useLazyExportAgentCsvQuery();
  const [getPendingCompany] = useLazyGetPendingAgentQuery();
  const [deleteUserById] = useDeleteByIduserMutation();
  const [getDataInsight] = useLazyGetPerformanceInsightsQuery();

  const [open1, setOpen1] = useState(false); // Control calendar visibility
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedChip, setSelectedChip] = useState("");
  const [month, setMonth] = useState(new Date());
  const [value, setValue] = React.useState(0);
  const [range, setRange] = useState<{
    from: Date | undefined;
    to: Date | undefined;
  }>({
    from: undefined,
    to: undefined,
  });

  const [dataCount, setDataCount] = useState({
    pendingRequests: 0,
    aproovedRequests: 0,
    rejectedRequests: 0,
    deactivatedRequests: 0,
  });

  const handleClose = () => {
    setOpen1(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen1(true);
  };

  const getFormattedRange = () => {
    if (range?.from && range?.to) {
      return `${moment(range.from).format("MM/DD/YYYY")} - ${moment(
        range.to
      ).format("MM/DD/YYYY")}`;
    } else if (range?.from) {
      return moment(range.from).format("MM/DD/YYYY");
    }
    return "";
  };

  const handleSelect = (selectedRange: any) => {
    if (selectedRange === undefined) {
      setRange({ from: undefined, to: undefined });
    } else {
      setRange(selectedRange);
    }
  };

  const handleChipClick = (value: any) => {
    if (value === selectedChip) {
      setSelectedChip("");
      setRange({ from: undefined, to: undefined });
      setMonth(moment().toDate());
      return;
    }
    setSelectedChip(value);

    let newRange = { from: moment().toDate(), to: moment().toDate() };
    const today = moment();

    if (value === "lastMonth") {
      newRange = {
        from: moment().subtract(1, "months").startOf("month").toDate(),
        to: moment().subtract(1, "months").endOf("month").toDate(),
      };
    } else if (value === "lastWeek") {
      newRange = {
        from: moment().subtract(7, "days").startOf("day").toDate(),
        to: today.toDate(),
      };
    } else if (value === "lastYear") {
      newRange = {
        from: moment().subtract(1, "years").startOf("year").toDate(),
        to: moment().subtract(1, "years").endOf("year").toDate(),
      };
    }
    setRange(newRange);
    setMonth(newRange.from);
  };

  const handleMonthChange = (newMonth: any) => {
    setMonth(newMonth);
  };

  const {
    data,
    isError,
    isLoading: load,
    isSuccess,
  } = useGetPendingAgentQuery({
    page: page,
    search: debouncedSearchTerm.trim(),
    limit: limit,
    startDate: range?.from
      ? moment(range?.from).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
      : "",
    endDate: range?.to
      ? moment(range?.to).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
      : "",
    isAdminAprooved: value + 1,
  });

  const getAgentsInsight = async (page: number, search: string) => {
    try {
      const response = await getDataInsight({
        agentInsightType: parseInt(searchParams.get("type") as string) || 1,
        superAgent: Boolean(searchParams.get("super")),
        type: 3,
        id: searchParams.get("companyid") || undefined,
        page: page,
        search: search,
        limit: limit,
      }).unwrap();

      if (response?.statusCode == 200) {
        if (parseInt(searchParams.get("type") as string) == 1) {
          setRows(response?.data?.liveListing?.data);
          setTotalCount1(response?.data?.liveListing?.total);
        } else if (parseInt(searchParams.get("type") as string) == 2) {
          setRows(response?.data?.leads?.data);
          setTotalCount1(response?.data?.leads?.total);
        } else {
          setRows(response?.data?.qualityScore?.data);
          setTotalCount1(response?.data?.qualityScore?.total);
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  const handleExportCsv = async () => {
    setIsLoading(true);
    try {
      const res = await exportCsv({
        startDate: range?.from
          ? moment(range?.from).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
        endDate: range?.to
          ? moment(range?.to).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
        search: debouncedSearchTerm?.trim(),
      }).unwrap();

      if (res?.statusCode === 200) {
        setIsLoading(false);
        window.open(res?.data);
        successToast("CSV exported successfully");
      } else {
        errorToast("Error: s3Location not found in response");
      }
    } catch (error: any) {
      setIsLoading(false);
      errorToast(error?.message);
    }
  };

  const changeUserStatus = async (userId: string, userStatus: boolean) => {
    setIsLoading(true);
    try {
      let body = {
        isBlocked: String(userStatus),
      };
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await editUSERbyId({
        body: encryptedBody,
        id: userId,
      }).unwrap();
      if (response?.statusCode === 200) {
        setIsLoading(false);
        successToast("Status updated successfully");
      }
    } catch (error: any) {
      setIsLoading(false);
      errorToast(error?.message);
      console.error(error);
    }
  };

  const handleDeleteCompany = async (userId: any) => {
    setIsLoading(true);
    try {
      const response = await deleteUserById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        setIsLoading(false);
        dispatch(
          setRequestCount({
            agents: agentCount ? agentCount - 1 : 0,
            company: companyCount,
            companyRequests: companyRequestCount,
            propertyRequest: propertyRequestCount,
            paymentRequestCount:paymentRequestCount,

          })
        );
        successToast("Agent deleted successfully");
        // fetchData(role, page, searchTerm);s
      }
    } catch (error: any) {
      setIsLoading(false);
      console.error(error);
      errorToast(error?.message || "");
    }
  };
  const [receiver, setReceiver] = useState<string>("1");

  const handleApprove = async (userId: any) => {
    const body = {
      isAdminAprooved: 2,
    };

    try {
      setIsLoading(true);

      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await editCompanybyId({
        body: encryptedBody,
        id: userId,
      }).unwrap();
      if (response?.statusCode === 200) {
        setIsLoading(false);
        setValue(1);
        dispatch(
          setRequestCount({
            agents: agentCount ? agentCount - 1 : 0,
            company: companyCount,
            companyRequests: companyRequestCount,
            propertyRequest: propertyRequestCount,
            paymentRequestCount:paymentRequestCount,

          })
        );
        successToast("Request accepted successfully");
      }
    } catch (error: any) {
      setIsLoading(false);

      errorToast(error?.data?.message || "");
    }
  };

  const handleReject = async () => {
    const body = {
      isAdminAprooved: 3,
      docRejectionReason: rejectionReason,
    };

    try {
      setIsLoading(true);

      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await editCompanybyId({
        body: encryptedBody,
        id: selectedCompany,
      }).unwrap();
      if (response?.statusCode === 200) {
        setIsLoading(false);
        setRejectionReason("");
        setValue(2);
        dispatch(
          setRequestCount({
            agents: agentCount ? agentCount - 1 : 0,
            company: companyCount,
            companyRequests: companyRequestCount,
            propertyRequest: propertyRequestCount,
            paymentRequestCount:paymentRequestCount,
           
          })
        );
        successToast("Request rejected successfully");
      }
    } catch (error: any) {
      setIsLoading(false);
      errorToast(error?.data?.message || "");
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setPage(1);
  };




  useEffect(() => {
    if (insight) {
      getAgentsInsight(page, debouncedSearchTerm);
    }
    // fetchData(role, page, debouncedSearchTerm);
  }, [page, debouncedSearchTerm, value, range]);

  useEffect(() => {
    if (isSuccess && data) {
      const res = data?.data;
      setRows(res?.user || []);
      setTotalCount1(res?.totalCount);
      setDataCount({
        pendingRequests: res?.pendingRequests || 0,
        aproovedRequests: res?.aproovedRequests || 0,
        rejectedRequests: res?.rejectedRequests || 0,
        deactivatedRequests: res?.deactivatedRequests || 0,
      });
    }
  }, [isSuccess, data]);

  useLayoutEffect(() => {
    if (state) {
      setValue(state);
    }
  }, []);

  return (
    <div>
      <Loader isLoad={isLoading || load} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">
            {insight
              ? `${Boolean(searchParams.get("super")) ? "Super " : ""} 
              Agents (Highest number of ${parseInt(searchParams.get("type") as string) == 1 ? "live listing" : parseInt(searchParams.get("type") as string) == 2 ? "leads" : "quality score"})`
              : "Manage Agents"}
          </h1>
        </div>
        <Card className="cards">
          <Box className="cards_header" marginBottom={"20px"}>
            <Box className="cards_header_left">
              <SearchBar2
                value={searchTerm}
                searchTerm={searchTerm}
                onCross={() => setSearchTerm("")}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <div className="control_group">
                <TextField
                  // label="Select Date Range"
                  variant="outlined"
                  hiddenLabel
                  className="text_field-range"
                  fullWidth
                  onClick={handleClick}
                  value={getFormattedRange()}
                  placeholder="Select Date Range"
                  InputProps={{
                    readOnly: true,
                    endAdornment: range?.from ? (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setRange({
                              from: undefined,
                              to: undefined,
                            });
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      <InputAdornment position="end">
                        <IconButton>
                          <CalendarTodayIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <Popper
                open={open1}
                anchorEl={anchorEl}
                placement="bottom-start"
                disablePortal
                style={{
                  position: "relative",
                  zIndex: 1,
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <Paper
                    elevation={3}
                    style={{ position: "relative", zIndex: 1 }}
                  >
                    <div
                      style={{
                        padding: 16,
                        borderRadius: 10,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <Chip
                          label="Last Week"
                          variant={
                            selectedChip === "lastWeek" ? "filled" : "outlined"
                          }
                          onClick={() => handleChipClick("lastWeek")}
                        />
                        <Chip
                          label="Last Month"
                          variant={
                            selectedChip === "lastMonth" ? "filled" : "outlined"
                          }
                          onClick={() => handleChipClick("lastMonth")}
                        />
                        <Chip
                          label="Last Year"
                          variant={
                            selectedChip === "lastYear" ? "filled" : "outlined"
                          }
                          onClick={() => handleChipClick("lastYear")}
                        />
                      </div>

                      <DayPicker
                        className="react-day-picker"
                        mode="range"
                        selected={range}
                        onSelect={handleSelect}
                        // numberOfMonths={10}
                        month={month}
                        onMonthChange={handleMonthChange}
                      // styles={{
                      //   months: { display: "flex", gap: "16px" },
                      //   month: { margin: 0 },
                      // }}
                      />
                    </div>
                  </Paper>
                </ClickAwayListener>
              </Popper>
            </Box>
            {insight ? null : (
              <Box className="cards_header_right">
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-agents/add")}
                >
                  <AddIcon /> Add Agent
                </Button>
                <Button className="btn btn_primary" onClick={handleExportCsv} disabled={rows1?.length === 0 ? true : false}>
                  <FileDownloadIcon /> Export CSV
                </Button>
              </Box>
            )}
          </Box>
          <Box className="custom_tabs">
            {insight ? null : (
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab
                  label={`Pending (${dataCount?.pendingRequests})`}
                  {...a11yProps(0)}
                />
                <Tab
                  label={`Approved (${dataCount?.aproovedRequests})`}
                  {...a11yProps(1)}
                />
                <Tab
                  label={`Rejected (${dataCount?.rejectedRequests})`}
                  {...a11yProps(2)}
                />
                <Tab
                  label={`Deactivated (${dataCount?.deactivatedRequests})`}
                  {...a11yProps(3)}
                />
              </Tabs>
            )}
            <CustomTabPanel value={value} index={0}>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Agent Id</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>Full name</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Company Name</TableCell>
                      {/* <TableCell>Tokens</TableCell>
                  <TableCell>Subscription</TableCell>
                  <TableCell>Subscription Expiry Date</TableCell> */}
                      <TableCell>Address</TableCell>
                      {insight ? null : <TableCell>Request</TableCell>}

                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {rows1?.length == "0" ? (
                      <TableRow>
                        <TableCell colSpan={12} align="center">
                          No agent found
                        </TableCell>
                      </TableRow>
                    ) : (
                      rows1?.map((row: any, i: number) => (
                        <TableRow key={row.i}>
                          <TableCell align="center">
                            {(page - 1) * 10 + i + 1}
                          </TableCell>
                          <TableCell>{row?.referenceId || "-"}</TableCell>

                          <TableCell>
                            <figure
                              onClick={() =>
                                navigate(`/manage-agents/details/${row?._id}`, {
                                  state: { tab: 0 },
                                })
                              }
                              className="user_img"
                            >
                              <img
                                src={
                                  row?.image ||
                                  `/static/images/user_placeholder.png`
                                }
                                alt=""
                              />
                            </figure>
                          </TableCell>
                          <TableCell>{row?.name || "-"}</TableCell>

                          <TableCell>
                            {row?.dialCode + row?.phoneNo || "-"}
                          </TableCell>
                          <TableCell>{row?.email || "-"}</TableCell>
                          <TableCell>
                            {row?.isSuperAgent ? "Titan" : "Normal agent"}
                          </TableCell>
                          <TableCell>{row?.companyName || "-"}</TableCell>
                          {/* <TableCell>{row?.token||"-"}</TableCell>
                    <TableCell>{row?.subscription||"-"}</TableCell>
                    <TableCell>{row?.expiryDate||"-"}</TableCell> */}
                          <TableCell>{row?.address || "-"}</TableCell>

                          {insight ? null : (
                            <TableCell>
                              <Select
                                fullWidth
                                className="select_div reqst_Select"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={receiver}
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                                displayEmpty
                              // onChange={(value: any) => {
                              //   handleReceiverChange(value, row?._id);
                              // }}
                              >
                                <MenuItem value="1" disabled>
                                  Pending
                                </MenuItem>
                                <MenuItem
                                  value="2"
                                  onClick={() => handleApprove(row?._id)}
                                >
                                  Approve
                                </MenuItem>
                                <MenuItem
                                  value="3"
                                  onClick={() => {
                                    setSelectedCompany(row?._id);
                                    setOpenReject(true);
                                  }}
                                >
                                  Reject
                                </MenuItem>
                              </Select>
                            </TableCell>
                          )}

                          <TableCell>
                            <Box className="table_actions">
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/manage-agents/details/${row?._id}`,
                                    { state: { tab: 0 } }
                                  )
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                              {insight ? null : (
                                <IconButton
                                  onClick={() =>
                                    navigate(`/manage-agents/edit/${row?._id}`)
                                  }
                                >
                                  <ModeEditIcon />
                                </IconButton>
                              )}
                              {insight ? null : (
                                <IconButton
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedId(row?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {rows1?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  module={rows1}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  limit={limit}
                  setLimit={setLimit}
                />
              ) : (
                ""
              )}
              <WarnModal
                open={open}
                setOpen={setOpen}
                handleDelete={() => handleDeleteCompany(selectedId)}
                name="User"
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Agent Id</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>Full name</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Company Name</TableCell>
                      <TableCell>Tokens</TableCell>
                      <TableCell>Subscription</TableCell>
                      <TableCell>Subscription Expiry Date</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>Account Status</TableCell>

                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {rows1?.length == "0" ? (
                      <TableRow>
                        <TableCell colSpan={12} align="center">
                          No agent found
                        </TableCell>
                      </TableRow>
                    ) : (
                      rows1?.map((row: any, i: number) => (
                        <TableRow key={row.i}>
                          <TableCell align="center">
                            {(page - 1) * 10 + i + 1}
                          </TableCell>
                          <TableCell>{row?.referenceId || "-"}</TableCell>

                          <TableCell>
                            <figure
                              onClick={() =>
                                navigate(`/manage-agents/details/${row?._id}`, {
                                  state: { insights: true, tab: 1 },
                                })
                              }
                              className="user_img"
                            >
                              <img
                                src={
                                  row?.image ||
                                  `/static/images/user_placeholder.png`
                                }
                                alt=""
                              />
                            </figure>
                          </TableCell>
                          <TableCell>{row?.name || "-"}</TableCell>

                          <TableCell>
                            {row?.dialCode + row?.phoneNo || "-"}
                          </TableCell>
                          <TableCell>{row?.email || "-"}</TableCell>
                          <TableCell>
                            {row?.isSuperAgent ? "Titan" : "Normal agent"}
                          </TableCell>
                          <TableCell>{row?.companyName || "-"}</TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {row?.token || "0"}
                          </TableCell>
                          <TableCell>{row?.subscriptionName || "-"}</TableCell>
                          <TableCell>
                            {moment(row?.SubscriptionEndDate).format(
                              "DD-MM-YYYY"
                            )}
                          </TableCell>
                          <TableCell>{row?.address || "-"}</TableCell>
                          <TableCell>
                            <Switch
                              defaultChecked={!row.isBlocked}
                              onChange={(e) => {
                                const isChecked = !e.target.checked;
                                changeUserStatus(row._id, isChecked);
                              }}
                              size="small"
                            />
                          </TableCell>

                          <TableCell>
                            <Box className="table_actions">
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/manage-agents/details/${row?._id}`,
                                    { state: { insights: true, tab: 1 } }
                                  )
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  navigate(`/manage-agents/edit/${row?._id}`)
                                }
                              >
                                <ModeEditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(row?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {rows1?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  module={rows1}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  limit={limit}
                  setLimit={setLimit}
                />
              ) : (
                ""
              )}
              <WarnModal
                open={open}
                setOpen={setOpen}
                handleDelete={() => handleDeleteCompany(selectedId)}
                name="Agent"
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Agent Id</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>Full name</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Company Name</TableCell>
                      {/* <TableCell>Tokens</TableCell>
                  <TableCell>Subscription</TableCell>
                  <TableCell>Subscription Expiry Date</TableCell> */}
                      <TableCell>Address</TableCell>

                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {rows1?.length == "0" ? (
                      <TableRow>
                        <TableCell colSpan={11} align="center">
                          No agent found
                        </TableCell>
                      </TableRow>
                    ) : (
                      rows1?.map((row: any, i: number) => (
                        <TableRow key={row.i}>
                          <TableCell align="center">
                            {(page - 1) * 10 + i + 1}
                          </TableCell>
                          <TableCell>{row?.referenceId || "-"}</TableCell>

                          <TableCell>
                            <figure
                              onClick={() =>
                                navigate(`/manage-agents/details/${row?._id}`, {
                                  state: { tab: 2 },
                                })
                              }
                              className="user_img"
                            >
                              <img
                                src={
                                  row?.image ||
                                  `/static/images/user_placeholder.png`
                                }
                                alt=""
                              />
                            </figure>
                          </TableCell>
                          <TableCell>{row?.name || "-"}</TableCell>

                          <TableCell>
                            {row?.dialCode + row?.phoneNo || "-"}
                          </TableCell>
                          <TableCell>{row?.email || "-"}</TableCell>
                          <TableCell>
                            {row?.isSuperAgent ? "Titan" : "Normal agent"}
                          </TableCell>
                          <TableCell>{row?.companyName || "-"}</TableCell>
                          {/* <TableCell>{row?.token||"-"}</TableCell>
                    <TableCell>{row?.subscriptionName||"-"}</TableCell>
                    <TableCell>{row?.expiryDate||"-"}</TableCell> */}
                          <TableCell>{row?.address || "-"}</TableCell>

                          <TableCell>
                            <Box className="table_actions">
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/manage-agents/details/${row?._id}`,
                                    { state: { tab: 2 } }
                                  )
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  navigate(`/manage-agents/edit/${row?._id}`)
                                }
                              >
                                <ModeEditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(row?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {rows1?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  module={rows1}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  limit={limit}
                  setLimit={setLimit}
                />
              ) : (
                ""
              )}
              <WarnModal
                open={open}
                setOpen={setOpen}
                handleDelete={() => handleDeleteCompany(selectedId)}
                name="Agent"
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Agent Id</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>Full name</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Company Name</TableCell>
                      <TableCell>Address</TableCell>

                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows1?.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={11} align="center">
                          No agent found
                        </TableCell>
                      </TableRow>
                    ) : (
                      rows1?.map((row: any, i: number) => (
                        <TableRow key={row?._id}>
                          {" "}
                          {/* Ensure `row._id` is unique */}
                          <TableCell align="center">
                            {(page - 1) * 10 + i + 1}
                          </TableCell>
                          <TableCell>{row?.referenceId || "-"}</TableCell>
                          <TableCell>
                            <figure
                              onClick={() =>
                                navigate(`/manage-agents/details/${row?._id}`, {
                                  state: { tab: 3 },
                                })
                              }
                              className="user_img"
                            >
                              <img
                                src={
                                  row?.image ||
                                  `/static/images/user_placeholder.png`
                                }
                                alt=""
                              />
                            </figure>
                          </TableCell>
                          <TableCell>{row?.name || "-"}</TableCell>
                          <TableCell>
                            {row?.dialCode + row?.phoneNo || "-"}
                          </TableCell>
                          <TableCell>{row?.email || "-"}</TableCell>
                          <TableCell>
                            {row?.isSuperAgent ? "Titan" : "Normal agent"}
                          </TableCell>
                          <TableCell>{row?.companyName || "-"}</TableCell>
                          <TableCell>{row?.address || "-"}</TableCell>
                          <TableCell>
                            <Box className="table_actions">
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/manage-agents/details/${row?._id}`,
                                    { state: { tab: 3 } }
                                  )
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {rows1?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  module={rows1}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  limit={limit}
                  setLimit={setLimit}
                />
              ) : (
                ""
              )}
              <WarnModal
                open={open}
                setOpen={setOpen}
                handleDelete={() => handleDeleteCompany(selectedId)}
                name="Agent"
              />
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
      <ReasonModal
        open={openReject}
        setOpen={setOpenReject}
        handleDelete={handleReject}
        reason={rejectionReason}
        setReason={setRejectionReason}
      />
    </div>
  );
};

export default ManageAgents;
