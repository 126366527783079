import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Tabs,
  Tab,

  Typography,
  InputAdornment,
  TextField,
} from "@mui/material";

import { errorToast, successToast } from "../../helpers";

import { isValidInput } from "../../utils/validation";
import SearchBar2 from "../../components/SearchBar2";
import Pagination from "../../components/Pagination";
import {
  useGetSubscriptionListingQuery,
  useLazyGetSubscriptionListingQuery,
}
  from "../../services/revenue";
import ClearIcon from '@mui/icons-material/Clear';

import {
  useLazyExportRevenueCsvSubscribeQuery,
}
  from "../../services/revenue";
import Loader from "../../helpers/constants/Loader";


function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const ManageRevenue = () => {

  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [rev1, setRev1] = useState<number>(0);
  const [exportCsv] = useLazyExportRevenueCsvSubscribeQuery();
  const [page, setPage] = useState(1);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setPage(1);
  };
  const today = new Date().toISOString().split('T')[0];
  const [isLoading, setISLoading] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [startDateSend, setStartDateSend] = useState<string>("");
  const [endDateSend, setEndDateSend] = useState<string>("");
  let totalPages = Math.ceil(totalCount / limit);
  const [subscriptionRows, setSubscriptionRows] = useState<any>([]);
  const [value, setValue] = React.useState(0);
  const formatDate = (dateString: string | number | Date) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };
  const [subscriptionRevenue] = useLazyGetSubscriptionListingQuery();


  function formatNumberWithCommas(value: string) {
    const [integer, decimal] = value.split('.');
    const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return decimal ? `${formattedInteger}.${decimal}` : formattedInteger;
  }

  const onPageChange1 = (newPage: number) => {
    setPage(newPage);
    setSubscriptionRows([]);
  };


  const handleDateChange = (event: any) => {
    setStartDate(event.target.value)
    const selectedDate = new Date(event.target.value);
    if (!isNaN(selectedDate.getTime())) {
      const utcDate = new Date(Date.UTC(selectedDate.getUTCFullYear(), selectedDate.getUTCMonth(), selectedDate.getUTCDate(), 0, 0, 0));

      setStartDateSend(utcDate.toISOString());
    } else {
      console.error("Invalid date selected");
    }
  };

  const handleDateChange1 = (event: any) => {
    setEndDate(event.target.value)
    const selectedDate = new Date(event.target.value);
    if (!isNaN(selectedDate.getTime())) {
      const utcDate = new Date(Date.UTC(selectedDate.getUTCFullYear(), selectedDate.getUTCMonth(), selectedDate.getUTCDate(), 0, 0, 0));

      setEndDateSend(utcDate.toISOString());
    } else {
      console.error("Invalid date selected");
    }
  };



 

  const { data, isError, isSuccess, isLoading: load } = useGetSubscriptionListingQuery({
    page: page,
    search: debouncedSearchTerm.trim(),
    limit: limit,
    startDate: startDateSend,
    endDate: endDateSend,
    revenueType: value === 0 ? 1 : value === 1 ? 2 : 3
  })

  useEffect(() => {
    if (isSuccess && data) {
      const res = data?.data;
      setSubscriptionRows(res?.details)
      setRev1(res?.totalRev );
      setTotalCount(res?.totalCount );
    }
  }, [isSuccess, data]);

  const handleExportCsv = async () => {
    try {
      const res = await exportCsv({
        revenueType: value === 0 ? 1 : value === 1 ? 2 : 3
      }).unwrap();
      if (res?.statusCode === 200) {
        window.open(res?.data);
        successToast("CSV exported successfully")
      } else {

        errorToast("Error: s3Location not found in response");
      }
    } catch (error: any) {
      errorToast(error?.message);
    }
  };


  function formatRevenue(labelValue: number) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + " B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
        ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + " M"
        : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
          ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + " K"
          : Math.abs(Number(labelValue));
  }


  return (
    <div>
      <Loader isLoad={isLoading || load} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Revenue</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header" style={{ marginBottom: 20 }}>
            <Box className="cards_header_left">
              <SearchBar2
                value={searchTerm}
                searchTerm={searchTerm}
                onCross={() => setSearchTerm("")}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
            </Box>
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={handleExportCsv}
              >
                <FileDownloadIcon /> Export CSV
              </Button>
            </Box>
          </Box>
          <Box className="crds_date">
            <div className="control_group">
              <Typography className="custom_label">Start Date</Typography>
              <TextField
                // hiddenTypography
                type="date"
                name="startDate"
                variant="outlined"
                fullWidth
                placeholder="Start Time"
                className="text_field"
                inputProps={{ maxLength: 30, max: today }}
                sx={{ maxWidth: "260px" }}
                value={startDate}
                onChange={handleDateChange}
                InputProps={{
                  endAdornment: (
                    startDate && (
                      <InputAdornment position="end">
                        <IconButton onClick={() => { setStartDate(""); setStartDateSend("") }}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  ),
                }}
              />
            </div>
            <div className="control_group">
              <Typography className="custom_label">End Date</Typography>
              <TextField
                hiddenLabel
                type="date"
                name="startDate"
                variant="outlined"
                fullWidth
                placeholder="End Time"
                className="text_field"
                sx={{ maxWidth: "260px" }}
                inputProps={{ maxLength: 30, max: today }}
                value={endDate}
                onChange={handleDateChange1}
                InputProps={{
                  endAdornment: (
                    endDate && (
                      <InputAdornment position="end">
                        <IconButton onClick={() => { setEndDateSend(""); setEndDate("") }}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  ),
                }}

              />
            </div>
            <h3>Total Revenue: <strong>AED {" " + rev1 && !isNaN(rev1) ? formatRevenue(rev1) : "0"}</strong></h3>
          </Box>
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Subscription" {...a11yProps(0)} />
              <Tab label="Boost Plan" {...a11yProps(1)} />
              <Tab label="Token" {...a11yProps(2)} />
            </Tabs>

            <CustomTabPanel value={value} index={0}>

              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>User Name</TableCell>
                      <TableCell>Subscription Name</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Subscription Start Date</TableCell>
                      <TableCell>Subscription End Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subscriptionRows?.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No revenue found in subscription
                        </TableCell>
                      </TableRow>
                    ) : (
                      subscriptionRows?.map((row: any, i: any) => (
                        <TableRow key={row.name}>
                          <TableCell align="center">
                            {(page - 1) * 10 + i + 1}
                          </TableCell>
                          <TableCell>{row?.userName || "-"}</TableCell>

                          <TableCell>{row?.subscriptionName || "-"}</TableCell>
                          <TableCell>AED {" " + row?.price? formatNumberWithCommas(String(row?.price)) : "-"}</TableCell>
                          <TableCell>{formatDate(row?.subscriptionStartDate) || "-"}</TableCell>
                          <TableCell>{formatDate(row?.subscriptionEndDate) || "-"}</TableCell>


                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {subscriptionRows?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  module={subscriptionRows}
                  page={page}
                  onPageChange={onPageChange1}
                  totalPages={totalPages}
                  limit={limit}
                  setLimit={setLimit}
                />
              ) : (
                ""
              )}
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>

              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>User Name</TableCell>
                      <TableCell>Boost Name</TableCell>
                      <TableCell>Boost Start Date</TableCell>
                      <TableCell>Boost End Date</TableCell>
                      <TableCell>Price</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subscriptionRows?.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No revenue found in boost profile
                        </TableCell>
                      </TableRow>
                    ) : (
                      subscriptionRows?.map((row: any, i: any) => (
                        <TableRow key={row.name}>
                          <TableCell align="center">
                            {(page - 1) * 10 + i + 1}
                          </TableCell>
                          <TableCell>{row?.userName || "-"}</TableCell>

                          <TableCell>{row?.boostProfileName || "-"}</TableCell>
                          <TableCell>{formatDate(row?.boostProfileStartDate) || "-"}</TableCell>
                          <TableCell>{formatDate(row?.boostProfileEndDate) || "-"}</TableCell>
                          <TableCell>AED {" " + row?.price? formatNumberWithCommas(String(row?.price)) : "-"}</TableCell>


                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {subscriptionRows?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  module={subscriptionRows}
                  page={page}
                  onPageChange={onPageChange1}
                  totalPages={totalPages}
                  limit={limit}
                  setLimit={setLimit}
                />
              ) : (
                ""
              )}
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>

              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>User Name</TableCell>
                      <TableCell>Tokens Used</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Transaction Id</TableCell>


                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subscriptionRows?.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No revenue found in Token
                        </TableCell>
                      </TableRow>
                    ) : (
                      subscriptionRows?.map((row: any, i: any) => (
                        <TableRow key={row.name}>
                          <TableCell align="center">
                            {(page - 1) * 10 + i + 1}
                          </TableCell>
                          <TableCell>{row?.userName || "-"}</TableCell>
                          <TableCell> {" " + row?.token? formatNumberWithCommas(String(row?.token)) : "-"}</TableCell>
                          <TableCell>AED {" " + row?.price? formatNumberWithCommas(String(row?.price)) : "-"}</TableCell>
                          <TableCell>{row?.transactionNo || "-"}</TableCell>


                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {subscriptionRows?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  module={subscriptionRows}
                  page={page}
                  onPageChange={onPageChange1}
                  totalPages={totalPages}
                  limit={limit}
                  setLimit={setLimit}
                />
              ) : (
                ""
              )}
            </CustomTabPanel>
          </Box>
        </Card>
      </div >
    </div>
  );
};

export default ManageRevenue;
