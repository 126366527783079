import {
  Drawer,
  Toolbar,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
  IconButton,
  Collapse,
} from "@mui/material";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import { useLocation, useNavigate } from "react-router-dom";
import GppBadIcon from "@mui/icons-material/GppBad";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import MergeTypeIcon from "@mui/icons-material/MergeType";
import RateReviewIcon from "@mui/icons-material/RateReview";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import InsightsIcon from "@mui/icons-material/Insights";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CabinIcon from "@mui/icons-material/Cabin";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import MessageIcon from "@mui/icons-material/Message";
import { SIDEBAR_WIDTH } from "../constants";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ReceiptIcon from "@mui/icons-material/Receipt";
import BusinessIcon from "@mui/icons-material/Business";
import { memo, PropsWithoutRef, useEffect, useRef, useState } from "react";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ThunderstormIcon from "@mui/icons-material/Thunderstorm";
import SettingsIcon from "@mui/icons-material/Settings";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PostAddIcon from "@mui/icons-material/PostAdd";
import EmailIcon from "@mui/icons-material/Email";
import GridViewIcon from "@mui/icons-material/GridView";
import DescriptionIcon from "@mui/icons-material/Description";
import MissedVideoCallIcon from "@mui/icons-material/MissedVideoCall";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import useAuth from "../hooks/useAuth";
import { socket } from "../utils/socket";
import {
  getAgentCount,
  getCompanyCount,
  getCompanyRequestCount,
  getEnquriesCount,
  getFeedbackCount,
  getNewListingActionTracker,
  getNewPropertyListing,
  getNewPropertyReports,
  getNewUsers,
  getPaymentRequestCount,
  getPropertyRequestCount,
  setNotificationCount,
  setRequestCount,
} from "../reducers/generalSlice";
import { useAppSelector } from "../hooks/store";
import { store } from "../app/store";
interface Props {
  mobileOpen: boolean;
  handleSidebarToggle: () => void;
}

function Sidebar({ mobileOpen, handleSidebarToggle }: PropsWithoutRef<Props>) {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedRef = useRef<HTMLDivElement | null>(null);
  const [isSelected, setIsSelected] = useState("");

  const [openMenu1, setOpenMenu1] = useState<boolean>(false);
  const [openMenu2, setOpenMenu2] = useState<boolean>(false);
  const [openMenu3, setOpenMenu3] = useState<boolean>(false);
  const [openMenu4, setOpenMenu4] = useState<boolean>(false);

  const agentCount = useAppSelector(getAgentCount);
  const companyCount = useAppSelector(getCompanyCount);
  const companyRequestCount = useAppSelector(getCompanyRequestCount);
  const propertyRequestCount = useAppSelector(getPropertyRequestCount);
  const paymentRequestCount = useAppSelector(getPaymentRequestCount);

  const newUsers = useAppSelector(getNewUsers);
  const newPropertyReports = useAppSelector(getNewPropertyReports);
  const newPropertyListing = useAppSelector(getNewPropertyListing);
  const newListingActionTracker = useAppSelector(getNewListingActionTracker);
  const newFeedback = useAppSelector(getFeedbackCount)
  const newEnquries = useAppSelector(getEnquriesCount)
  const newPaymentRequest = useAppSelector(getPaymentRequestCount)
  console.log(newPaymentRequest, "newPaymentRequest");
  const drawer = (
    <>
      <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
        <figure className="logo_cntnr" onClick={() => navigate("/dashboard")}>
          <img src="/static/images/logo.png" alt="" />
        </figure>
        {/* <h1 style={{color: 'white', marginLeft:50}}>ZAID</h1> */}
        <IconButton
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "white",
            display: { lg: "none" },
          }}
          onClick={handleSidebarToggle}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>

      <List sx={{ flexGrow: 1 }} className="sidebr-lst">
        {/* Dashboard */}
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/dashboard"}
          onClick={() => navigate("/dashboard")}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Dashboard" />
        </ListItemButton>

        {/* Performance Dashboard */}
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/listing-performance/dashboard"}
          onClick={() => navigate("/listing-performance/dashboard")}
        >
          <ListItemIcon>
            <GridViewIcon />
          </ListItemIcon>
          <ListItemText
            className="lstitm-txt"
            primary="Performance Dashboard"
          />
        </ListItemButton>

        {/* Super Agent Dashboard */}
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-insights"}
          onClick={() => navigate("/manage-insights")}
        >
          <ListItemIcon>
            <InsightsIcon />
          </ListItemIcon>
          <ListItemText
            className="lstitm-txt"
            primary="Super Agent Dashboard"
          />
        </ListItemButton>

        {/* Registration */}
        <ListItemButton
          className="lst-itm"
          onClick={() => {
            if (
              location.pathname === "/manage-users" ||
              location.pathname.startsWith("/manage-users/details") ||
              location.pathname.startsWith("/manage-users/edit") ||
              location.pathname.startsWith("/manage-users/usersform") ||
              location.pathname.startsWith("/manage-users/token/details") ||
              location.pathname.startsWith("/manage-users/property/details") ||
              location.pathname === "/manage-agents" ||
              location.pathname.startsWith("/manage-agents/details") ||
              location.pathname.startsWith("/manage-reels/agents") ||
              location.pathname.startsWith("/manage-agents/edit") ||
              location.pathname.startsWith("/manage-agents/add") ||
              location.pathname.startsWith("/manage-agents/edit/chat") ||
              location.pathname.startsWith("/manage-agent/token/details") ||
              location.pathname.startsWith("/manage-agents/property/details") ||
              location.pathname.startsWith(
                "/manage-agents/property-listing/details"
              ) ||
              location.pathname === "/manage-company-requests" ||
              location.pathname.startsWith(
                "/manage-company-requests/details"
              ) ||
              location.pathname === "/manage-company" ||
              location.pathname.startsWith("/manage-reels/company") ||
              location.pathname.startsWith("/manage-company/details") ||
              location.pathname.startsWith("/manage-company/edit") ||
              location.pathname.startsWith("/manage-company/add") ||
              location.pathname.startsWith("/manage-company/edit/chat") ||
              location.pathname.startsWith(
                "/company/property-listing/details"
              ) ||
              location.pathname.startsWith(
                "manage-users/property/details/ChatInbox"
              )||
              location.pathname.startsWith(
                "/short-term-rentals"
              )
            ) {
              return;
            }

            setOpenMenu1(!openMenu1);
          }}
          selected={
            location.pathname === "/manage-users" ||
            location.pathname.startsWith("/manage-users/details") ||
            location.pathname.startsWith("/manage-users/edit") ||
            location.pathname.startsWith("/manage-users/usersform") ||
            location.pathname.startsWith("/manage-users/token/details") ||
            location.pathname.startsWith("/manage-users/property/details") ||
            location.pathname === "/manage-agents" ||
            location.pathname.startsWith("/manage-agents/details") ||
            location.pathname.startsWith("/manage-reels/agents") ||
            location.pathname.startsWith("/manage-agents/edit") ||
            location.pathname.startsWith("/manage-agents/add") ||
            location.pathname.startsWith("/manage-agents/edit/chat") ||
            location.pathname.startsWith("/manage-agent/token/details") ||
            location.pathname.startsWith("/manage-agents/property/details") ||
            location.pathname.startsWith(
              "/manage-agents/property-listing/details"
            ) ||
            location.pathname === "/manage-company-requests" ||
            location.pathname.startsWith("/manage-company-requests/details") ||
            location.pathname === "/manage-company" ||
            location.pathname.startsWith("/manage-reels/company") ||
            location.pathname.startsWith("/manage-company/details") ||
            location.pathname.startsWith("/manage-company/edit") ||
            location.pathname.startsWith("/manage-company/add") ||
            location.pathname.startsWith("/manage-company/edit/chat") ||
            location.pathname.startsWith("/company/property-listing/details") ||
            location.pathname.startsWith(
              "manage-users/property/details/ChatInbox"
            )||
            location.pathname.startsWith(
              "/short-term-rentals"
            )
          }
        >
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Registration" />
          {openMenu1 ? <ExpandLess /> : <ExpandMore />}
          {companyRequestCount || agentCount || companyCount ? (
            <span className="empty_badge" />
          ) : null}
        </ListItemButton>

        <Collapse
          in={
            openMenu1 ||
            location.pathname === "/manage-users" ||
            location.pathname.startsWith("/manage-users/details") ||
            location.pathname.startsWith("/manage-users/edit") ||
            location.pathname.startsWith("/manage-users/usersform") ||
            location.pathname.startsWith("/manage-users/token/details") ||
            location.pathname.startsWith("/manage-users/property/details") ||
            location.pathname === "/manage-agents" ||
            location.pathname.startsWith("/manage-agents/details") ||
            location.pathname.startsWith("/manage-reels/agents") ||
            location.pathname.startsWith("/manage-agents/edit") ||
            location.pathname.startsWith("/manage-agents/add") ||
            location.pathname.startsWith("/manage-agents/edit/chat") ||
            location.pathname.startsWith("/manage-agent/token/details") ||
            location.pathname.startsWith("/manage-agents/property/details") ||
            location.pathname.startsWith(
              "/manage-agents/property-listing/details"
            ) ||
            location.pathname === "/manage-company-requests" ||
            location.pathname.startsWith("/manage-company-requests/details") ||
            location.pathname === "/manage-company" ||
            location.pathname.startsWith("/manage-reels/company") ||
            location.pathname.startsWith("/manage-company/details") ||
            location.pathname.startsWith("/manage-company/edit") ||
            location.pathname.startsWith("/manage-company/add") ||
            location.pathname.startsWith("/manage-company/edit/chat") ||
            location.pathname.startsWith("/company/property-listing/details") ||
            location.pathname.startsWith(
              "manage-users/property/details/ChatInbox"
            )||
            location.pathname.startsWith(
              "/short-term-rentals"
            )
          }
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            {/* Manage Users */}
            <ListItemButton
              className="lst-itm"
              selected={
                location.pathname === "/manage-users" ||
                location.pathname.startsWith("/manage-users/details") ||
                location.pathname.startsWith("/manage-users/edit") ||
                location.pathname.startsWith("/manage-users/usersform") ||
                location.pathname.startsWith("/manage-users/token/details") ||
                location.pathname.startsWith("/manage-users/property/details")
              }
              onClick={() => {
                navigate("/manage-users");
                setOpenMenu1(true);
              }}
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText className="lstitm-txt" primary="Manage Users" />
              {newUsers && newUsers > 0 ? (
                <span className="badge">{newUsers || 0}</span>
              ) : null}
            </ListItemButton>

            {/* Manage Agents */}
            <ListItemButton
              className="lst-itm"
              selected={
                location.pathname === "/manage-agents" ||
                location.pathname.startsWith("/manage-agents/details") ||
                location.pathname.startsWith("/manage-reels/agents") ||
                location.pathname.startsWith("/manage-agents/edit") ||
                location.pathname.startsWith("/manage-agents/add") ||
                location.pathname.startsWith("/manage-agents/edit/chat") ||
                location.pathname.startsWith("/manage-agent/token/details") ||
                location.pathname.startsWith(
                  "/manage-agents/property/details"
                ) ||
                location.pathname.startsWith(
                  "/manage-agents/property-listing/details"
                )
              }
              onClick={() => {
                navigate("/manage-agents");
                setOpenMenu1(true);
              }}
            >
              <ListItemIcon
                ref={
                  isSelected?.includes("/manage-agents") ? selectedRef : null
                }
              >
                <SupportAgentIcon />
              </ListItemIcon>
              <ListItemText className="lstitm-txt" primary="Manage Agents" />

              {agentCount && agentCount > 0 ? (
                <span className="badge">{agentCount || 0}</span>
              ) : null}
            </ListItemButton>

            {/* Manage Company Requests */}
            <ListItemButton
              className="lst-itm"
              selected={
                location.pathname === "/manage-company-requests" ||
                location.pathname.startsWith("/manage-company-requests/details")
              }
              onClick={() => {
                navigate("/manage-company-requests");
                setOpenMenu1(true);
              }}
            >
              <ListItemIcon
                ref={
                  isSelected?.includes("/manage-company-requests")
                    ? selectedRef
                    : null
                }
              >
                <RunningWithErrorsIcon />
              </ListItemIcon>
              <ListItemText
                className="lstitm-txt"
                primary="Manage Company Requests"
              />
              {companyRequestCount && companyRequestCount > 0 ? (
                <span className="badge">{companyRequestCount || 0}</span>
              ) : null}
            </ListItemButton>

            {/* short term rentals */}
            <ListItemButton
              className="lst-itm"
              selected={
                location.pathname === "/short-term-rentals" ||
                location.pathname.startsWith("/short-term-rentals")
              }
              onClick={() => navigate("/short-term-rentals")}
              ref={
                isSelected?.includes("/short-term-rentals")
                  ? selectedRef
                  : null
              }
            >
              <ListItemIcon>
                <CabinIcon />
              </ListItemIcon>
              <ListItemText className="lstitm-txt" primary="Short Term Rentals" />
            </ListItemButton>

            {/* Manage Company */}
            <ListItemButton
              className="lst-itm"
              selected={
                location.pathname === "/manage-company" ||
                location.pathname.startsWith("/manage-reels/company") ||
                location.pathname.startsWith("/manage-company/details") ||
                location.pathname.startsWith("/manage-company/edit") ||
                location.pathname.startsWith("/manage-company/add") ||
                location.pathname.startsWith("/manage-company/edit/chat") ||
                location.pathname.startsWith(
                  "/company/property-listing/details"
                ) ||
                location.pathname.startsWith(
                  "manage-users/property/details/ChatInbox"
                )
              }
              onClick={() => {
                navigate("/manage-company");
                setOpenMenu1(true);
              }}
            >
              <ListItemIcon
                ref={
                  isSelected?.includes("/manage-company") ? selectedRef : null
                }
              >
                <ApartmentIcon />
              </ListItemIcon>
              <ListItemText className="lstitm-txt" primary="Manage Company" />
              {companyCount && companyCount > 0 ? (
                <span className="badge">{companyCount || 0}</span>
              ) : null}
            </ListItemButton>
          </List>
        </Collapse>

        {/* {Affiliate  members} */}

        <ListItemButton
          className="lst-itm"
          onClick={() => {
            if (
              location.pathname === "/affiliate-members" ||
              location.pathname.startsWith("/affiliate-members-add") ||
              location.pathname.startsWith("/affiliate-members-edit") ||
              location.pathname.startsWith("/affiliate-members-details") ||
              location.pathname.startsWith("/affiliate-payment-requests")) {
              return;
            }

            setOpenMenu4(!openMenu4);
          }}
          selected={
            location.pathname === "/affiliate-members" ||
            location.pathname.startsWith("/affiliate-members-add") ||
            location.pathname.startsWith("/affiliate-members-edit") ||
            location.pathname.startsWith("/affiliate-members-details") ||
            location.pathname.startsWith("/affiliate-payment-requests")
          }

        >
          <ListItemIcon>
            <SupervisorAccountIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Affiliate Members" />
          {openMenu4 ? <ExpandLess /> : <ExpandMore />}

          {newPaymentRequest ? (
            <span className="empty_badge" />
          ) : null}
        </ListItemButton>

        <Collapse
          in={
            openMenu4 ||
            location.pathname.startsWith("/affiliate-members-add") ||
            location.pathname.startsWith("/affiliate-members-edit") ||
            location.pathname.startsWith("/affiliate-members-details") ||
            location.pathname.startsWith("/affiliate-payment-requests")

          }
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            {/* Manage members */}
            <ListItemButton
              className="lst-itm"
              selected={
                location.pathname === "/affiliate-members" ||
                location.pathname.startsWith("/affiliate-members-add") ||
                location.pathname.startsWith("/affiliate-members-edit") ||
                location.pathname.startsWith("/affiliate-members-details")
              }
              onClick={() => {
                navigate("/affiliate-members");
                setOpenMenu4(true);
              }}
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText className="lstitm-txt" primary="Manage Members" />

            </ListItemButton>

            {/* Manage requests */}
            <ListItemButton
              className="lst-itm"
              selected={
                location.pathname === "/affiliate-payment-requests"

              }
              onClick={() => {
                navigate("/affiliate-payment-requests");
                setOpenMenu4(true);
              }}
            >
              <ListItemIcon
                ref={
                  isSelected?.includes("/affiliate-payment-requests") ? selectedRef : null
                }
              >
                <SupportAgentIcon />
              </ListItemIcon>
              <ListItemText className="lstitm-txt" primary="Payment Requests" />
              {paymentRequestCount && paymentRequestCount > 0 ? (
                <span className="badge">{paymentRequestCount || 0}</span>
              ) : null}
            </ListItemButton>

          </List>
        </Collapse>




        {/* Manage Ads */}
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-ads" ||
            location.pathname.startsWith("/manage-ads/details") ||
            location.pathname.startsWith("/manage-ads/edit") ||
            location.pathname.startsWith("/manage-ads/add")
          }
          onClick={() => navigate("/manage-ads")}
        >
          <ListItemIcon
            ref={isSelected?.includes("/manage-ads") ? selectedRef : null}
          >
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Ads" />
        </ListItemButton>

        {/* Manage Banners */}
        {/* <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-banners/" ||
            location.pathname === "/manage-banners" ||
            location.pathname.startsWith("/manage-banners/details") ||
            location.pathname.startsWith("/manage-banners/edit") ||
            location.pathname.startsWith("/manage-banners/add")
          }
          onClick={() => navigate("/manage-banners")}
        >
          <ListItemIcon
            ref={isSelected?.includes("/manage-banners") ? selectedRef : null}
          >
            <PostAddIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Banners" />
        </ListItemButton> */}

        {/* Manage Categories */}
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-categories" ||
            location.pathname.startsWith("/manage-categories/details") ||
            location.pathname.startsWith("/manage-categories/edit") ||
            location.pathname.startsWith("/manage-categories/add")
          }
          onClick={() => navigate("/manage-categories")}
        >
          <ListItemIcon
            ref={
              isSelected?.includes("/manage-categories") ? selectedRef : null
            }
          >
            <ReceiptIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Categories" />
        </ListItemButton>

        {/* Manage Reels */}
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-reels" ||
            location.pathname.startsWith("/manage-reels/details") ||
            location.pathname.startsWith("/manage-reels/edit") ||
            location.pathname.startsWith("/manage-reels/add")
          }
          onClick={() => navigate("/manage-reels")}
        >
          <ListItemIcon
            ref={isSelected?.includes("/manage-reels") ? selectedRef : null}
          >
            <MissedVideoCallIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Reels" />
        </ListItemButton>

        {/* Manage Amenities */}
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-amenities" ||
            location.pathname.startsWith("/manage-amenities/details") ||
            location.pathname.startsWith("/manage-amenities/edit") ||
            location.pathname.startsWith("/manage-amenities/add")
          }
          onClick={() => navigate("/manage-amenities")}
        >
          <ListItemIcon
            ref={isSelected?.includes("/manage-amenities") ? selectedRef : null}
          >
            <AddHomeWorkIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Amenities" />
        </ListItemButton>

        {/* Manage Property */}
        <ListItemButton
          className="lst-itm"
          onClick={() => {
            if (
              location.pathname === "/manage-propertyType" ||
              location.pathname.startsWith("/manage-propertyType/details") ||
              location.pathname.startsWith("/manage-propertyType/edit") ||
              location.pathname.startsWith("/manage-propertyType/add") ||
              location.pathname === "/manage-propertyStatus" ||
              location.pathname.startsWith("/manage-propertyStatus/edit") ||
              location.pathname.startsWith("/manage-propertyStatus/add") ||
              location.pathname === "/manage-property-requests" ||
              location.pathname.startsWith(
                "/manage-property-requests/details"
              ) ||
              location.pathname === "/property-listing" ||
              location.pathname.startsWith("/property-listing/details") ||
              location.pathname.startsWith("/property-listing/edit") ||
              location.pathname.startsWith("/property-listing/add") ||
              location.pathname === "/listing-action-tracker" ||
              location.pathname.startsWith("/listing-action-tracker/details") ||
              location.pathname === "/manage-property-reports"
            ) {
              return;
            }
            setOpenMenu2(!openMenu2);
          }}
          selected={
            location.pathname === "/manage-propertyType" ||
            location.pathname.startsWith("/manage-propertyType/details") ||
            location.pathname.startsWith("/manage-propertyType/edit") ||
            location.pathname.startsWith("/manage-propertyType/add") ||
            location.pathname === "/manage-propertyStatus" ||
            location.pathname.startsWith("/manage-propertyStatus/edit") ||
            location.pathname.startsWith("/manage-propertyStatus/add") ||
            location.pathname === "/manage-property-requests" ||
            location.pathname.startsWith("/manage-property-requests/details") ||
            location.pathname === "/property-listing" ||
            location.pathname.startsWith("/property-listing/details") ||
            location.pathname.startsWith("/property-listing/edit") ||
            location.pathname.startsWith("/property-listing/add") ||
            location.pathname === "/listing-action-tracker" ||
            location.pathname.startsWith("/listing-action-tracker/details") ||
            location.pathname === "/manage-property-reports"
          }
        >
          <ListItemIcon>
            <CabinIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Property" />
          {openMenu2 ? <ExpandLess /> : <ExpandMore />}
          {propertyRequestCount ||
            newPropertyListing ||
            newPropertyReports ||
            newListingActionTracker ? (
            <span className="empty_badge" />
          ) : null}
        </ListItemButton>
        <Collapse
          in={
            openMenu2 ||
            location.pathname === "/manage-propertyType" ||
            location.pathname.startsWith("/manage-propertyType/details") ||
            location.pathname.startsWith("/manage-propertyType/edit") ||
            location.pathname.startsWith("/manage-propertyType/add") ||
            location.pathname === "/manage-propertyStatus" ||
            location.pathname.startsWith("/manage-propertyStatus/edit") ||
            location.pathname.startsWith("/manage-propertyStatus/add") ||
            location.pathname === "/manage-property-requests" ||
            location.pathname.startsWith("/manage-property-requests/details") ||
            location.pathname === "/property-listing" ||
            location.pathname.startsWith("/property-listing/details") ||
            location.pathname.startsWith("/property-listing/edit") ||
            location.pathname.startsWith("/property-listing/add") ||
            location.pathname === "/listing-action-tracker" ||
            location.pathname.startsWith("/listing-action-tracker/details") ||
            location.pathname === "/manage-property-reports"
          }
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            {/* Property Type */}
            <ListItemButton
              className="lst-itm"
              selected={
                location.pathname === "/manage-propertyType" ||
                location.pathname.startsWith("/manage-propertyType/details") ||
                location.pathname.startsWith("/manage-propertyType/edit") ||
                location.pathname.startsWith("/manage-propertyType/add")
              }
              onClick={() => navigate("/manage-propertyType")}
              ref={
                isSelected?.includes("/manage-propertyType")
                  ? selectedRef
                  : null
              }
            >
              <ListItemIcon>
                <MergeTypeIcon />
              </ListItemIcon>
              <ListItemText className="lstitm-txt" primary="Property Type" />
            </ListItemButton>

            {/* Property Status */}
            {/* <ListItemButton
              className="lst-itm"
              selected={
                location.pathname === "/manage-propertyStatus" ||
                location.pathname.startsWith("/manage-propertyStatus/edit") ||
                location.pathname.startsWith("/manage-propertyStatus/add")
              }
              onClick={() => navigate("/manage-propertyStatus")}
              ref={
                isSelected?.includes("/manage-propertyStatus")
                  ? selectedRef
                  : null
              }
            >
              <ListItemIcon>
                <CabinIcon />
              </ListItemIcon>
              <ListItemText className="lstitm-txt" primary="Property Status" />
            </ListItemButton> */}

            {/* Property Requests */}
            <ListItemButton
              className="lst-itm"
              selected={
                location.pathname === "/manage-property-requests" ||
                location.pathname.startsWith(
                  "/manage-property-requests/details"
                )
              }
              onClick={() => navigate("/manage-property-requests")}
              ref={
                isSelected?.includes("/manage-property-requests")
                  ? selectedRef
                  : null
              }
            >
              <ListItemIcon>
                <TimelapseIcon />
              </ListItemIcon>
              <ListItemText
                className="lstitm-txt"
                primary="Property Requests"
              />
              {propertyRequestCount && propertyRequestCount > 0 ? (
                <span className="badge">{propertyRequestCount || 0}</span>
              ) : null}
            </ListItemButton>



            {/* Property Listing */}
            <ListItemButton
              className="lst-itm"
              selected={
                location.pathname === "/property-listing" ||
                location.pathname.startsWith("/property-listing/details") ||
                location.pathname.startsWith("/property-listing/edit") ||
                location.pathname.startsWith("/property-listing/add")
              }
              onClick={() => navigate("/property-listing")}
              ref={
                isSelected?.includes("/property-listing") ? selectedRef : null
              }
            >
              <ListItemIcon>
                <CalendarTodayIcon />
              </ListItemIcon>
              <ListItemText className="lstitm-txt" primary="Property Listing" />
              {newPropertyListing && newPropertyListing > 0 ? (
                <span className="badge">{newPropertyListing || 0}</span>
              ) : null}
            </ListItemButton>

            {/* Listing Action Tracker */}
            <ListItemButton
              className="lst-itm"
              selected={
                location.pathname === "/listing-action-tracker" ||
                location.pathname.startsWith("/listing-action-tracker/details")
              }
              onClick={() => navigate("/listing-action-tracker")}
              ref={
                isSelected?.includes("/listing-action-tracker")
                  ? selectedRef
                  : null
              }
            >
              <ListItemIcon>
                <GppBadIcon />
              </ListItemIcon>
              <ListItemText
                className="lstitm-txt"
                primary="Listing Action Tracker"
              />
              {newListingActionTracker && newListingActionTracker > 0 ? (
                <span className="badge">{newListingActionTracker || 0}</span>
              ) : null}
            </ListItemButton>

            {/* Property Reports */}
            <ListItemButton
              className="lst-itm"
              selected={location.pathname === "/manage-property-reports"}
              onClick={() => navigate("/manage-property-reports")}
              ref={
                isSelected?.includes("/manage-property-reports")
                  ? selectedRef
                  : null
              }
            >
              <ListItemIcon>
                <FlagCircleIcon />
              </ListItemIcon>
              <ListItemText className="lstitm-txt" primary="Property Reports" />
              {newPropertyReports && newPropertyReports > 0 ? (
                <span className="badge">{newPropertyReports || 0}</span>
              ) : null}
            </ListItemButton>
          </List>
        </Collapse>

        {/* Manage Subscription */}
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-subscription" ||
            location.pathname.startsWith("/manage-subscription/details") ||
            location.pathname.startsWith("/manage-subscription/edit") ||
            location.pathname.startsWith("/manage-subscription/add")
          }
          onClick={() => navigate("/manage-subscription")}
        >
          <ListItemIcon
            ref={
              isSelected?.includes("/manage-subscription") ? selectedRef : null
            }
          >
            <SubscriptionsIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Subscription" />
        </ListItemButton>

        {/* Manage Chat */}
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-chat" ||
            location.pathname.startsWith("/manage-chat/details") ||
            location.pathname.startsWith("/manage-chat/edit") ||
            location.pathname.startsWith("/manage-chat/add")
          }
          onClick={() => navigate("/manage-chat")}
        >
          <ListItemIcon
            ref={isSelected?.includes("/manage-chat") ? selectedRef : null}
          >
            <MessageIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Chat" />
        </ListItemButton>

        {/* Manage Revenue */}
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-revenue" ||
            location.pathname.startsWith("/manage-revenue/details") ||
            location.pathname.startsWith("/manage-revenue/edit") ||
            location.pathname.startsWith("/manage-revenue/add")
          }
          onClick={() => navigate("/manage-revenue")}
        >
          <ListItemIcon
            ref={isSelected?.includes("/manage-revenue") ? selectedRef : null}
          >
            <AttachMoneyIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Revenue" />
        </ListItemButton>

        {/* Manage Boost Plan */}
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-boost" ||
            location.pathname.startsWith("/manage-boost/details") ||
            location.pathname.startsWith("/manage-boost/edit") ||
            location.pathname.startsWith("/manage-boost/add")
          }
          onClick={() => navigate("/manage-boost")}
        >
          <ListItemIcon
            ref={isSelected?.includes("/manage-boost") ? selectedRef : null}
          >
            <ThunderstormIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Boost Plan" />
        </ListItemButton>

        {/* Subscribed Emails */}
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-emails"}
          onClick={() => navigate("/manage-emails")}
        >
          <ListItemIcon
            ref={isSelected?.includes("/manage-emails") ? selectedRef : null}
          >
            <EmailIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Subscribed Emails" />
        </ListItemButton>

        {/* Notification Management */}
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-notifications" ||
            location.pathname.startsWith("/manage-notifications/add") ||
            location.pathname.startsWith("/recieved-notifications")
          }
          onClick={() => navigate("/manage-notifications")}
        >
          <ListItemIcon
            ref={
              isSelected?.includes("/manage-notifications") ? selectedRef : null
            }
          >
            <NotificationsActiveIcon />
          </ListItemIcon>
          <ListItemText
            className="lstitm-txt"
            primary="Notification Management"
          />
        </ListItemButton>

        {/* Analytics */}
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/analytics"}
          onClick={() => navigate("/analytics")}
        >
          <ListItemIcon
            ref={isSelected?.includes("/analytics") ? selectedRef : null}
          >
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Analytics" />
        </ListItemButton>

        {/* Settings */}
        <ListItemButton
          className="lst-itm"
          onClick={() => {
            if (
              location.pathname === "/settings" ||
              location.pathname === "/manage-enqueries" ||
              location.pathname === "/manage-feedbacks" ||
              location.pathname === "/cms"
            )
              return;
            setOpenMenu3(!openMenu3);
          }}
          selected={
            location.pathname === "/settings" ||
            location.pathname === "/manage-enqueries" ||
            location.pathname === "/manage-feedbacks" ||
            location.pathname === "/cms"
          }
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Settings" />
          {openMenu3 ? <ExpandLess /> : <ExpandMore />}
          {newEnquries || newFeedback ? (
            <span className="empty_badge" />
          ) : null}
        </ListItemButton>
        <Collapse
          in={
            openMenu3 ||
            location.pathname === "/settings" ||
            location.pathname === "/manage-enqueries" ||
            location.pathname === "/manage-feedbacks" ||
            location.pathname === "/cms"
          }
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            {/* General */}
            <ListItemButton
              className="lst-itm"
              selected={location.pathname === "/settings"}
              onClick={() => navigate("/settings")}
              ref={isSelected?.includes("/settings") ? selectedRef : null}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText className="lstitm-txt" primary="General" />
            </ListItemButton>

            {/* Manage Enquiries */}
            <ListItemButton
              className="lst-itm"
              selected={location.pathname === "/manage-enqueries"}
              onClick={() => navigate("/manage-enqueries")}
              ref={
                isSelected?.includes("/manage-enqueries") ? selectedRef : null
              }
            >
              <ListItemIcon>
                <PsychologyAltIcon />
              </ListItemIcon>
              <ListItemText className="lstitm-txt" primary="Manage Enquiries" />
              {newEnquries && newEnquries > 0 ? (
                <span className="badge">{newEnquries || 0}</span>
              ) : null}
            </ListItemButton>

            {/* Manage Feedbacks */}
            <ListItemButton
              className="lst-itm"
              selected={location.pathname === "/manage-feedbacks"}
              onClick={() => navigate("/manage-feedbacks")}
              ref={
                isSelected?.includes("/manage-feedbacks") ? selectedRef : null
              }
            >
              <ListItemIcon>
                <RateReviewIcon />
              </ListItemIcon>
              <ListItemText className="lstitm-txt" primary="Manage Feedbacks" />
              {newFeedback && newFeedback > 0 ? (
                <span className="badge">{newFeedback || 0}</span>
              ) : null}
            </ListItemButton>

            {/* Manage CMS */}
            <ListItemButton
              className="lst-itm"
              selected={location.pathname === "/cms"}
              onClick={() => navigate("/cms")}
              ref={isSelected?.includes("/cms") ? selectedRef : null}
            >
              <ListItemIcon>
                <SummarizeTwoToneIcon />
              </ListItemIcon>
              <ListItemText className="lstitm-txt" primary="Manage CMS" />
            </ListItemButton>
          </List>
        </Collapse>

        {/* Manage Listing */}
        {/* <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-listing"
          }
          onClick={() => navigate("/manage-listing")}
        >
          <ListItemIcon ref={isSelected?.includes("/manage-listing") ? selectedRef : null}>
            <ChecklistRtlIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Listing" />
        </ListItemButton> */}

        {/* Manage Sub-categories */}
        {/* <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-Sub-categories" ||
            location.pathname.startsWith("/manage-Sub-categories/add") ||
            location.pathname.startsWith("/manage-Sub-categories/edit/:id") 
          
          }
          onClick={() => navigate("/manage-Sub-categories")}
        >
          <ListItemIcon>
            <CategoryIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Sub-categories" />
        </ListItemButton> */}

        {/* Newsletter Emails */}
        {/* <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-newsletter-emails"
          }
          onClick={() => navigate("/manage-newsletter-emails")}
        >
          <ListItemIcon ref={isSelected?.includes("/manage-newsletter-emails") ? selectedRef : null}>
            <ContactMailIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Newsletter Emails" />
        </ListItemButton> */}
      </List>
    </>
  );

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    setIsSelected(location.pathname);
  }, [isSelected]);

  const newRequestSocket = async () => {
    if (socket) {
      socket?.on("newRequestsReceived", async (response: any) => {
        const state = store.getState(); // Get the latest Redux state
        const agents = state.general.agents;
        const companyRequests = state.general.companyRequests;
        const company = state.general.company;
        const propertyRequest = state.general.propertyRequest;
        const paymentRequest = state.general.paymentRequest;

        console.log(response, "response of socket");

        if (response?.statusCode === 200) {
          store.dispatch(
            setRequestCount({
              agents: response?.data?.pushType === 9 ? agents + 1 : agents,
              company: response?.data?.pushType === 10 ? company + 1 : company,
              companyRequests:
                response?.data?.pushType === 28
                  ? companyRequests + 1
                  : companyRequests,
              propertyRequest:
                response?.data?.pushType === 24 ||
                  response?.data?.pushType === 25
                  ? propertyRequest + 1
                  : propertyRequest,
              paymentRequestCount: response?.data?.pushType === 35 ? paymentRequest + 1 : paymentRequest,
            })
          );

        }
      });
    }
  };

  useEffect(() => {
    newRequestSocket();
    return () => {
      if (socket) {
        socket?.off("newRequestsReceived");
      }
    };
  }, []);

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { lg: 0 } }}
      className="sidebar-main-div"
    >
      <Drawer
        className="sidebar_drawer"
        variant="temporary"
        open={mobileOpen}
        onClose={handleSidebarToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: SIDEBAR_WIDTH,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        className="sidebar_drawer"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            width: SIDEBAR_WIDTH,
            boxSizing: "border-box",
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

export default memo(Sidebar);
