import io from "socket.io-client";

// This is the socket URL development link
// export const Socket_URL = `https://getpropadminsocket.appgrowthcompany.com/`;

// staging links
export const Socket_URL = `https://getprop-admin-staging.appgrowthcompany.com/`;


export const socket = io(Socket_URL, {
  autoConnect: false,
});
