import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Box,
    Button,
    Card,
    Chip,
    ClickAwayListener,
    IconButton,
    InputAdornment,
    Paper,
    Popper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Clear } from "@mui/icons-material";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import Pagination from "../../components/Pagination";
import SearchBar2 from "../../components/SearchBar2";
import { isValidInput } from "../../utils/validation";
import Loader from "../../helpers/constants/Loader";
import { useGetAllActionTrackerQuery } from "../../services/actionTracker";
import moment from "moment";
import { DayPicker } from "react-day-picker";
import PermitUpdateModal from "../../components/UpdatePermitModal";
const ListingActionTracker = () => {
    const navigate = useNavigate();
    const [rows1, setRows1] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    let totalPages = Math.ceil(totalCount / limit);
    const [selectedId, setSelectedId] = useState<string>("")
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
    const onPageChange = (newPage: number) => {
        setPage(newPage);
        setRows1([]);
    };
    const [open, setOpen] = useState<boolean>(false);
    const [open2, setOpen2] = useState(false); // Control calendar visibility
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [selectedChip, setSelectedChip] = useState("")
    const [month, setMonth] = useState(new Date());
    const [range, setRange] = useState<{
        from: Date | undefined;
        to: Date | undefined;
    }>({
        from: undefined,
        to: undefined,
    });


    const handleClose = () => {
        setOpen2(false);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen2(true);
    };

    const getFormattedRange = () => {
        if (range?.from && range?.to) {
            return `${moment(range.from).format("MM/DD/YYYY")} - ${moment(
                range.to
            ).format("MM/DD/YYYY")}`;
        } else if (range?.from) {
            return moment(range.from).format("MM/DD/YYYY");
        }
        return "";
    };

    const handleSelect = (selectedRange: any) => {
        if (selectedRange === undefined) {
            setRange({ from: undefined, to: undefined });
        } else {
            setRange(selectedRange);
        }
    };

    const handleChipClick = (value: any) => {
        if (value === selectedChip) {
            setSelectedChip("");
            setRange({ from: undefined, to: undefined });
            setMonth(moment().toDate());
            return;
        }
        setSelectedChip(value);

        let newRange = { from: moment().toDate(), to: moment().toDate() };
        const today = moment();

        if (value === "lastMonth") {
            newRange = {
                from: moment().subtract(1, "months").startOf("month").toDate(),
                to: moment().subtract(1, "months").endOf("month").toDate(),
            };
        } else if (value === "lastWeek") {
            newRange = {
                from: moment().subtract(7, "days").startOf("day").toDate(),
                to: today.toDate(),
            };
        } else if (value === "lastYear") {
            newRange = {
                from: moment().subtract(1, "years").startOf("year").toDate(),
                to: moment().subtract(1, "years").endOf("year").toDate(),
            };
        }
        setRange(newRange);
        setMonth(newRange.from);
    };

    const handleMonthChange = (newMonth: any) => {
        setMonth(newMonth);
    };


    const { data, isLoading, isError, isSuccess } = useGetAllActionTrackerQuery({
        page: page, search: debouncedSearchTerm.trim(), limit: limit,
        startDate: range?.from
            ? moment(range?.from).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
            : "",
        endDate: range?.to
            ? moment(range?.to).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
            : "",
    })




    useEffect(() => {
        if (isSuccess && data) {
            const res = data?.data;
            setRows1(res?.expiredProperties || []);
            setTotalCount(res?.totalCount);
        }
    }, [isSuccess, data]);



    return (
        <div>
            <Loader isLoad={isLoading} />
            <div className="main_loyout">
                <div className="dashboard">
                    <h1 className="mn_hdng">Property Listing Action Tracker</h1>
                </div>
                <Card className="cards">
                    <Box className="cards_header">
                        <Box className="cards_header_left">
                            <SearchBar2
                                value={searchTerm}
                                searchTerm={searchTerm}
                                onCross={() => setSearchTerm("")}
                                setDebouncedSearchTerm={setDebouncedSearchTerm}
                                onChange={(val: any) => {
                                    if (isValidInput(val.target.value)) {
                                        setSearchTerm(val.target.value);
                                    }
                                }}
                            />
                            <div className="control_group" >
                                <TextField
                                    // label="Select Date Range"
                                    variant="outlined"
                                    hiddenLabel
                                    className="text_field-range"
                                    fullWidth
                                    onClick={handleClick}
                                    value={getFormattedRange()}
                                    placeholder="Select Date Range"
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: range?.from ? (
                                            <InputAdornment position="end" style={{ marginLeft: -50 }}>
                                                <IconButton
                                                    onClick={() => {
                                                        setRange({
                                                            from: undefined,
                                                            to: undefined
                                                        });
                                                    }}
                                                >
                                                    <Clear />
                                                </IconButton>
                                            </InputAdornment>
                                        ) : (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    <CalendarTodayIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Popper
                                    open={open2}
                                    anchorEl={anchorEl}
                                    placement="bottom-start"
                                    disablePortal
                                    style={{
                                        position: "relative",
                                        zIndex: 1,
                                    }}
                                >
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <Paper elevation={3} style={{ position: "relative", zIndex: 1 }}>
                                            <div
                                                style={{
                                                    padding: 16,
                                                    borderRadius: 10,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-around",
                                                    }}
                                                >
                                                    <Chip
                                                        label="Last Week"
                                                        variant={selectedChip === "lastWeek" ? "filled" : "outlined"}
                                                        onClick={() => handleChipClick("lastWeek")}
                                                    />
                                                    <Chip
                                                        label="Last Month"
                                                        variant={selectedChip === "lastMonth" ? "filled" : "outlined"}
                                                        onClick={() => handleChipClick("lastMonth")}
                                                    />
                                                    <Chip
                                                        label="Last Year"
                                                        variant={selectedChip === "lastYear" ? "filled" : "outlined"}
                                                        onClick={() => handleChipClick("lastYear")}
                                                    />
                                                </div>

                                                <DayPicker
                                                    className="react-day-picker"
                                                    mode="range"
                                                    selected={range}
                                                    onSelect={handleSelect}
                                                    // numberOfMonths={10}
                                                    month={month}
                                                    onMonthChange={handleMonthChange}
                                                // styles={{
                                                //   months: { display: "flex", gap: "16px" },
                                                //   month: { margin: 0 },
                                                // }}
                                                />
                                            </div>
                                        </Paper>
                                    </ClickAwayListener>
                                </Popper>
                            </div>
                        </Box>

                    </Box>
                    <TableContainer className="table_container">
                        <Box className="heading"></Box>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">S.No</TableCell>
                                    <TableCell>Image</TableCell>
                                    <TableCell>Property Name</TableCell>
                                    <TableCell>Owner</TableCell>
                                    <TableCell>Permit number</TableCell>
                                    <TableCell>Expiry Date</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows1.length === 0 ? (
                                    <TableCell colSpan={7} align="center">
                                        No Expired document property found
                                    </TableCell>
                                ) : (rows1?.map((row: any, i: any) => (
                                    <TableRow key={row.i}>
                                        <TableCell align="center">
                                            {(page - 1) * 10 + i + 1}
                                        </TableCell>
                                        <TableCell>
                                            <figure className="user_img">
                                                <img
                                                    src={
                                                        row?.image ||
                                                        `/static/images/product1.png`
                                                    }
                                                    alt=""
                                                />
                                            </figure>
                                        </TableCell>
                                        <TableCell>{row?.properties?.name || "-"}</TableCell>
                                        <TableCell>{row?.agents?.name || "-"
                                        }</TableCell>
                                        <TableCell>
                                            {row?.advertisementPermitNo || "-"}

                                        </TableCell>

                                        <TableCell>{row?.endDate ? moment(row?.endDate).format("DD-MM-YYYY") : "-"
                                        }</TableCell>

                                        <TableCell>
                                            <Box className="table_actions">
                                                <IconButton
                                                onClick={() => navigate(`/listing-action-tracker/details/${row?._id}`)}
                                                >
                                                    <VisibilityIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => { setSelectedId(row?._id); setOpen(true) }
                                                    }
                                                >
                                                    <ModeEditIcon />
                                                </IconButton>

                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </div>
            {rows1?.length > 0 ? (
                <Pagination
                    setPage={setPage}
                    module={rows1}
                    limit={limit}
                    setLimit={setLimit}
                    page={page}
                    onPageChange={onPageChange}
                    totalPages={totalPages}
                />
            ) : (
                ""
            )}
            <PermitUpdateModal
                open={open}
                setOpen={setOpen}
                id={selectedId}
            />
        </div>
    );
};

export default ListingActionTracker;
