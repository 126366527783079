import emptySplitApi from "../utils/rtk";

import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
    statusCode: number;
    message: string;
    s3Location?: string
};



interface CategoryResponse extends CommonResponseType {
    data: any;

}

interface QueryParams {
    userId?: any;
    page?: number;
    limit?: number;
}

export const actionTrackerApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({

        getAllActionTracker: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string, limit?: number, startDate?: string, endDate?: string }>({
            query: ({ page, search, limit, startDate, endDate }) => {
                let url = `${END_POINTS.listingActionTracker}?page=${page}&search=${search}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`;

                return {
                    url: url,
                    method: "GET",
                };
            },
            keepUnusedDataFor: 1,
            providesTags: ["GETACTIONTRACKER"]
        }),

    }),
});

export const {
    useGetAllActionTrackerQuery

} = actionTrackerApi;
