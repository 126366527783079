import React, { Dispatch, SetStateAction, useState } from "react";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { generateResponsiveStyle } from "../utils/modalStyle";
import { usePostFeedbackApiMutation } from "../services/feedback";
import Loader from "../helpers/constants/Loader";
import { errorToast, successToast } from "../helpers";
import { generateEncryptedKeyBody } from "../utils/crypto";
import { CommonBody } from "../types/General";
import { useEditPropertyMutation } from "../services/property";

type props = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    id: string;
};

const PermitUpdateModal = ({ open, setOpen, id }: props) => {
    const style = generateResponsiveStyle();
    const [permit, setPermit] = useState<string>("")
    const [postRevert, { isLoading }] = useEditPropertyMutation();

    const postReply = async () => {
        const body = {
            advertisementPermitNo: permit
        }
        try {
            const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
            let res = await postRevert({
                body: encryptedBody,
                id: id
            }).unwrap();

            if (res?.statusCode === 200) {
                successToast(res?.data?.message || "Permit number updated successfully")
                setOpen(false)
            }
        } catch (error: any) {

            errorToast(error?.data?.message)
            setOpen(false)
        }
    }

    return (
        <>
            <Loader isLoad={isLoading} />
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="modal_title">
                        <Typography id="modal-modal-title" component="h2">Add a new Permit Number</Typography>
                        <CloseIcon className="cross_icn_logout" onClick={() => setOpen(false)} />
                    </div>

                    <div className="modal_body">
                        <div className="control_group">
                            <TextField
                                className="text_field"
                                hiddenLabel
                                placeholder="Type here.."
                                fullWidth
                                type="text"
                                variant="outlined"
                                value={permit}
                                onChange={(e) => setPermit(e.target.value)}
                            />
                        </div>
                        <div className="form_btn">
                            <Button
                                disabled={permit === ""}
                                className="btn btn_primary"
                                onClick={() => {
                                    postReply()
                                }}
                            >
                                Update
                            </Button>
                            <Button
                                className="btn btn_secondary"
                                onClick={() => setOpen(false)}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
};

export default PermitUpdateModal;
