import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Typography,
    Dialog,
    DialogContent,
    IconButton
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useLazyGetSpecificUserByIdQuery } from "../../../services/users";


const MemberDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [ByIdDetails, setByIdDetails] = useState<any>();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [fetchApi, { isLoading }] = useLazyGetSpecificUserByIdQuery();

    const fetchDataById = async () => {
        try {
            const response = await fetchApi({ userId: id, detailsType: 1 }).unwrap();
            if (response.statusCode === 200) {
                setByIdDetails(response?.data || {});
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleOpenModal = (imageUrl: string) => {
        setSelectedImage(imageUrl);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedImage(null);
    };

    useEffect(() => {
        if (id) {
          fetchDataById();
        }
      }, []);

    return (
        <div>
            {/* <Loader isLoad={isLoading} /> */}
            <div className="main_loyout">
                <div className="dashboard">
                    <h1 className="mn_hdng">View Member Details</h1>
                    <Button
                        className="btn btn_primary"
                        onClick={() => navigate("/affiliate-members")}
                    >
                        Back
                    </Button>
                </div>
                <Card className="cards">
                    <CardContent sx={{ p: 1 }}>
                        <Grid container spacing={2} className="view_box">
                            <Grid item xs={12} className="view_box_list">
                                <Grid container spacing={3}>
                                    <Grid item lg={4} md={6} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5">Image</Typography>
                                            <Grid item lg={2} md={2} sm={6} xs={12}>
                                                <figure className="profile_img">
                                                    <img
                                                        style={{ borderRadius: 0, cursor: 'pointer', width: '100%' }}
                                                        src={ByIdDetails?.image || `/static/images/product1.png`}
                                                        alt="Banner"
                                                    // onClick={() => handleOpenModal(ByIdDetails?.image)}
                                                    />
                                                    <div className="overlay">
                                                        <img
                                                            onClick={() => handleOpenModal(ByIdDetails?.image)}
                                                            src='/static/images/visibility.png' alt='img'
                                                        />
                                                    </div>
                                                </figure>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12} />
                                    <Grid item lg={4} md={6} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5">Name</Typography>
                                            <Typography component="p">{ByIdDetails?.name || "-"}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={4} md={6} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5">Email</Typography>
                                            <Typography component="p">{ByIdDetails?.email || "-"}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={4} md={6} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5">Phone Number</Typography>
                                            <Typography component="p"> {ByIdDetails?.dialCode + "-" + ByIdDetails?.phoneNo ||
                                                "-"}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={4} md={6} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5">Referral code</Typography>
                                            <Typography component="p"> {ByIdDetails?.referralCode ||
                                                "-"}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={4} md={6} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5">Reference Id</Typography>
                                            <Typography component="p"> {ByIdDetails?.referenceId ||
                                                "-"}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={4} md={6} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5">Commision (%)</Typography>
                                            <Typography component="p"> {ByIdDetails?.affiliateCommission ||
                                                "-"}</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>

            {/* Modal for displaying the larger image */}
            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                PaperProps={{
                    sx: {
                        width: 600,  // Fixed width
                        height: 400, // Fixed height
                        maxWidth: 'unset', // Prevent maxWidth from overriding the fixed size
                        overflow: 'hidden', // Prevent scrollbars
                    }
                }}
            >
                <DialogContent sx={{ p: 0, position: 'relative', overflow: 'hidden' }}>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseModal}
                        sx={{
                            position: "absolute",
                            top: 10,
                            right: 10,
                            color: "red",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <img
                            src={selectedImage || ''}
                            alt="Selected"
                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                        />
                    </Box>
                </DialogContent>
            </Dialog>
            {/* <ImageModal open={openModal} handleClose={handleCloseModal} image={selectedImage} /> */}
        </div>
    );
};

export default MemberDetails;
