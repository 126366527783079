import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Modal,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useLazyGetSpecificUserByIdQuery } from "../../services/users";
import { errorToast } from "../../helpers";
import { ImageModal } from "../../components";
import Loader from "../../helpers/constants/Loader";
import moment from "moment";
import { handleDownload } from "../../utils/uploadMedia";

const AgentDetails = () => {
  const { _id } = useParams();
  const [ByIdDetails, setByIdDetails] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const [link, setLink] = useState<string>("");
  const [fetchApi, { isLoading }] = useLazyGetSpecificUserByIdQuery();

  const fetchDataById = async (_id: any) => {
    try {
      const response = await fetchApi({ userId: _id, detailsType: 1 }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response?.data || []);
      }
    } catch (error: any) {
      errorToast(error?.message || "");
    }
  };

  useEffect(() => {
    fetchDataById(_id);
  }, [_id]);



  const handleOpenModal = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedImage("");
  };


  // const getDocName = (num: number) => {
  //   switch (num) {
  //     case 1:
  //       return "Trade License";
  //     case 2:
  //       return "RERA Registration Documents (For Dubai Companies)";
  //     case 3:
  //       return "Brokerage License (Other than Dubai Companies)";
  //     case 4:
  //       return "Emirates Id";
  //     case 5:
  //       return "Power of Attorney documents";
  //     default:
  //       return "Invalid Document Number";
  //   }
  // };

  return (
    <>
      <Loader isLoad={isLoading} />

      <Card className="cards" sx={{ mb: 2 }}>
        <CardContent sx={{ p: 1 }}>
          <h3 className="card_title">Personal Info</h3>
          <Grid container spacing={2} className="view_box">
            <Grid item lg={2} md={2} sm={6} xs={12}>
              <figure className="profile_img">
                <img
                  src={
                    ByIdDetails?.image
                      ? ByIdDetails?.image
                      : `/static/images/user_placeholder.png`
                  }
                  alt="Profile"
                />
                <div className="overlay">
                  <img
                    onClick={() =>
                      handleOpenModal(
                        ByIdDetails?.image
                          ? ByIdDetails?.image
                          : "/static/images/user_placeholder.png"
                      )
                    }
                    src="/static/images/visibility.png"
                    alt="img"
                  />
                </div>
              </figure>
            </Grid>
            <Grid item xs={10} className="view_box_list mb_0">
              <Grid container spacing={3}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Reference Id</Typography>
                    <Typography component="p">
                      {ByIdDetails?.referenceId || "-"}
                    </Typography>
                  </Box>
                </Grid>
                {ByIdDetails?.companyName ? (
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Company Name</Typography>
                      <Typography component="p">
                        {ByIdDetails?.companyName || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                ) : (
                  ""
                )}
                {/* Personal info fields */}
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Full Name</Typography>
                    <Typography component="p">
                      {ByIdDetails?.name || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Email</Typography>
                    <Typography component="p">
                      {ByIdDetails?.email || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Phone Number</Typography>
                    <Typography component="p">
                      {ByIdDetails?.dialCode + "-" + ByIdDetails?.phoneNo ||
                        "-"}
                    </Typography>
                  </Box>
                </Grid>
                {ByIdDetails?.emiratesId ? (
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Emirates Id</Typography>
                      <Typography component="p">
                        {ByIdDetails?.emiratesId || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                ) : ("")}

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">BRN</Typography>
                    <Typography component="p">
                      {ByIdDetails?.BRN || "-"}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Available Tokens</Typography>
                    <Typography component="p">
                      {ByIdDetails?.token || "-"}
                    </Typography>
                  </Box>
                </Grid>
                {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Subscription</Typography>
                    <Typography component="p">
                      {ByIdDetails?.currentSubscription || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">
                      Subscription Expiry Date
                    </Typography>
                    <Typography component="p">
                      {ByIdDetails?.subscriptionExpiryDate
                        ? formatDate(ByIdDetails?.subscriptionExpiryDate)
                        : "-"}
                    </Typography>
                  </Box>
                </Grid> */}
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Account Status</Typography>
                    <Typography component="p">
                      {ByIdDetails?.isBlocked === false ? "Active" : "Blocked"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">
                      {" "}
                      Total Active properties
                    </Typography>
                    <Typography component="p">{ByIdDetails?.activeProperties || "0"}</Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">
                      {" "}
                      Total Sold properties
                    </Typography>
                    <Typography component="p">{ByIdDetails?.TotalSoldProperties || "0"}</Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">
                      Total Rental properties
                    </Typography>
                    <Typography component="p">{ByIdDetails?.TotalRentalProperties || "0"}</Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">
                      Total Inactive properties
                    </Typography>
                    <Typography component="p">{ByIdDetails?.inactiveProperties || "0"}</Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">
                      Total Claimed Transactions
                    </Typography>
                    <Typography component="p">{ByIdDetails?.claimedTransactions || "0"}</Typography>
                  </Box>
                </Grid>
                {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Country</Typography>
                    <Typography component="p">
                      {ByIdDetails?.country || "-"}
                    </Typography>
                  </Box>
                </Grid> */}
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Address</Typography>
                    <Typography component="p">
                      {ByIdDetails?.address || "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Complete Address</Typography>
                    <Typography component="p">
                      {ByIdDetails?.companyLocation || "-"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card className="cards">
        <CardContent sx={{ p: 1 }}>
          <h3 className="card_title">Documents</h3>
          <Grid container spacing={2} className="view_box">
            <Grid item xs={12} className="view_box_list">
              <Grid container spacing={3}>
                {/* Documents section */}
                {ByIdDetails?.documents?.length
                  ? ByIdDetails?.documents?.map((item: any) => {
                    return (
                      <Grid item xs={12}>
                        <Box>

                          <Typography
                            className="document_head"
                            variant="h5"
                            fontSize={"18px"}
                          >
                            {
                              (item?.isAdditionalDocument
                                ? "Broker ID/ Residency Visa/ Employment Contract"
                                : ByIdDetails?.agentEmirate === 1
                                  ? "RERA Broker Card"
                                  : ByIdDetails?.agentEmirate === 2
                                    ? "ADREC Broker Card or DARI Card"
                                    : "Labour Card or Employment Visa")}
                          </Typography>
                          <p
                            className="doc_details"
                            style={{ fontSize: "16px" }}
                          >
                            {item?.documentNumber
                              ? item?.documentNumber
                              : ""}
                          </p>
                          <p
                            className="doc_details"
                            style={{ fontSize: "16px" }}
                          >
                            {item?.ORN !== ""
                              ? "Expiry Date: " +
                              moment(item?.expiryDate).format("DD/MM/YYYY")
                              : ""}
                          </p>
                          <Box className="docs_div ">
                            {item?.frontImage ? (
                              <Box
                                display="flex"
                                alignItems="center"
                                border="1px dashed #ccc"
                                borderRadius="4px"
                                padding="8px"
                                gap="8px"
                                width="100%"
                                justifyContent="space-between"
                                margin="0"
                                className="mb_0"
                              >
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  width="100%"
                                >
                                  <img
                                    src={"/static/images/pdf_image.png"}
                                    alt="Preview"
                                    onClick={() => { window.open(item?.frontImage) }}
                                    style={{
                                      height: "40px",
                                      width: "40px",
                                      objectFit: "contain",
                                      marginRight: "8px",
                                    }}
                                  />

                                  <Box
                                    display="flex"
                                    flexGrow={1}
                                    justifyContent="flex-end"
                                    gap={1}
                                  >
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      onClick={() =>
                                        handleDownload(item?.frontImage, Date.now().toString())
                                      }
                                      style={{ textTransform: "none" }}
                                    >
                                      Download
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            ) : null}
                            {item?.backImage ? (
                              <Box
                                display="flex"
                                alignItems="center"
                                border="1px dashed #ccc"
                                borderRadius="4px"
                                padding="8px"
                                gap="8px"
                                width="100%"
                                justifyContent="space-between"
                              >
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  width="100%"
                                >
                                  <img
                                    src={"/static/images/pdf_image.png"}
                                    alt="Preview"
                                    onClick={() => { window.open(item?.backImage) }}

                                    style={{
                                      height: "40px",
                                      width: "40px",
                                      objectFit: "contain",
                                      marginRight: "8px",
                                    }}
                                  />

                                  <Box
                                    display="flex"
                                    flexGrow={1}
                                    justifyContent="flex-end"
                                    gap={1}
                                  >
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      onClick={() =>
                                        handleDownload(item?.backImage, Date.now().toString())
                                      }
                                      style={{ textTransform: "none" }}
                                    >
                                      Download
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            ) : null}

                            {/* <figure>
                                <img
                                  src={item?.backImage}
                                  alt="Back Document"
                                  style={{
                                    cursor: "pointer",
                                    maxWidth: "300px",
                                  }}
                                />
                                <div className="overlay">
                                  <img
                                    onClick={() =>
                                      handleOpenModal(item?.backImage)
                                    }
                                    src="/static/images/visibility.png"
                                    alt="img"
                                  />
                                </div>
                              </figure> */}
                          </Box>
                        </Box>
                      </Grid>
                    );
                  })
                  : ""}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <ImageModal
        open={openModal}
        handleClose={handleCloseModal}
        image={selectedImage}
      />

    </>
  );
};

export default AgentDetails;
