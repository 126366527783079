import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';

// import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import {
  usePostAddSubscriptionMutation,
  useEditSubscriptionMutation,
  useLazyGetSpecificSubscriptionByIdQuery,
} from "../../services/subscription";
import { errorToast, successToast, warnToast } from "../../helpers/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";

import { CommonBody, SubscriptionPackage } from "../../types/General";
import Loader from "../../helpers/constants/Loader";
import { useLazyGetPendingCompanyQuery } from "../../services/users";
import { UploadMedia, Uploadpdf } from "../../utils/uploadMedia";
import { EmbedPDF } from "@simplepdf/react-embed-pdf";
import { isNumberPrice } from "../../utils/validation";
import moment from "moment";

const AddSubscription = () => {
  const navigate = useNavigate();
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const { _id } = useParams();
  const [contract, setContract] = useState<any>(null);
  const [subscriptionType, setSubscriptionType] = useState<string>("");
  const [ByIdDetails, setByIdDetails] = useState<SubscriptionPackage>();
  const [fetchById] = useLazyGetSpecificSubscriptionByIdQuery();
  const [addSubscription, { isLoading }] = usePostAddSubscriptionMutation();
  const [updateSubscription] = useEditSubscriptionMutation();
  const [companies, setCompanies] = useState<any>([]);
  const [getApprovedCompany] = useLazyGetPendingCompanyQuery();
  // const handleCategoryChange = (event: SelectChangeEvent) => {
  //   setCategory(event.target.value as string);
  //   formik.setFieldValue('category', event.target.value);
  // };
  const [endDate, setEndDate] = useState<string>("");
  const [endDateSend, setEndDateSend] = useState<string>("");

  const handleDateChange1 = (event: any) => {
    setEndDate(event.target.value)
    const selectedDate = new Date(event.target.value);
    if (!isNaN(selectedDate.getTime())) {
      const utcDate = new Date(Date.UTC(selectedDate.getUTCFullYear(), selectedDate.getUTCMonth(), selectedDate.getUTCDate(), 0, 0, 0));

      setEndDateSend(utcDate.toISOString());
    } else {
      console.error("Invalid date selected");
    }
  };


  const handlesubscriptionTypeChange = (event: SelectChangeEvent) => {
    setSubscriptionType(event.target.value as string);
    formik.setFieldValue("subscriptionType", event.target.value);
  };

  function formatNumberWithCommas(value: string) {
    const [integer, decimal] = value.split('.');
    const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return decimal ? `${formattedInteger}.${decimal}` : formattedInteger;
  }

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (!file) {
        setContract(null);
        return;
      }

      let res;
      if (file.type === "application/pdf") {
        res = await Uploadpdf(file);
        if (res?.statusCode === 200) {
          setContract(res?.data?.[0]?.location); // Assuming pdfUrl is the URL of the uploaded PDF
        } else {
          errorToast(res?.message);
        }
      } else {
        res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          setContract(res?.data?.[0]?.location); // Assuming image is the URL of the uploaded image
        } else {
          errorToast(res?.message);
        }
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      errorToast("Error uploading file. Please try again.");
    }
  };

  const formik = useFormik({
    initialValues: {
      name: ByIdDetails?.name || "",
      price: ByIdDetails?.price || "",
      // features: ByIdDetails?.features || "",
      description: ByIdDetails?.description || "",
      tokens: ByIdDetails?.token || "",
      // agents: ByIdDetails?.noOfAgents || "",
      // category: category || "",
      subscriptionType: subscriptionType || "",
      companyId: ByIdDetails?.companyId || "",
      bonus: ByIdDetails?.bonus || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Subscription Name is required")
        .max(40, "Maximum 40 characters are allowed")
        .min(2, "Minimum 2 characters are required"),
      price: Yup.string()
        .required("Price is required")
        .max(10, "Maximum 10 characters are allowed"),
      tokens: Yup.string()
        .required("This field is required")
        .max(10, "Maximum 10 characters are allowed"),
      // agents: Yup.string()
      //   .required("This field is required")
      //   .max(10, "Maximum 10 characters are allowed"),

      // category: Yup.string().required("User Type is required"),
      subscriptionType: Yup.string().required("Subscription Type is required"),
      bonus: Yup.number().typeError("Bonus must be a number"),
      // features: Yup.string()
      //   .required("Features is required")
      //   .max(200, "Maximum 200 characters are allowed")
      //   .min(2, "Minimum 2 characters are required"),
      description: Yup.string()
        .required("Description is required")
        .max(300, "Maximum 300 characters are allowed")
        .min(2, "Minimum 2 characters are required"),
    }),
    onSubmit: async (values) => {

      if (selectedCompany === "") {
        warnToast("Please select a company");
        return;
      }
      if (!contract) {
        warnToast("Please Upload a contract");
        return;
      }
      if (formik.values.bonus && !endDate) {
        warnToast("Please select End date for bonus")
        return;
      }
      if(endDate && !formik.values.bonus){
        warnToast("Please enter bonus for the selected end date")
        return;
      }
      let body = {
        name: values.name,
        description: values.description,
        price: Number(String(values.price).replace(/,/g, '')),
        userType: "5",
        userId: selectedCompany || "",
        subscriptionPack: subscriptionType,
        token: Number(values.tokens),
        contract: contract || "",
        bonus: Number(values.bonus) || 0,
        bonusEndDate: endDateSend || null
        // features: values.features,
        // noOfAgents: Number(values.agents),
      };

      console.log(body, "body");

      try {
        const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

        if (_id) {
          const response = await updateSubscription({
            id: _id,
            body: encryptedBody,
          }).unwrap();
          if (response.statusCode === 200) {
            successToast("Subscription Updated Successfully");
            navigate("/manage-subscription");
          } else {
            errorToast(response.message);
          }
        } else {
          const response = await addSubscription(encryptedBody).unwrap();
          if (response.statusCode === 200) {
            successToast("Subscription Added Successfully");
            navigate("/manage-subscription");
          } else {
            errorToast(response.message);
          }
        }
      } catch (e: any) {
        console.error("API call failed", e);
        errorToast(e?.data?.message);
      }
    },
  });

  const fetchCompanies = async () => {
    try {
      const response = await getApprovedCompany({
        page: 1,
        search: "",
        limit: 100,
        range: { from: "", to: "" },
        isAdminAprooved: 2,
      }).unwrap();
      if (response.statusCode === 200) {
        setCompanies(response?.data?.user || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDataById = async (id: any) => {
    try {
      const response = await fetchById({ userId: id }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response?.data);
        // setCategory(response.data.userType.toString());
        setContract(response?.data?.contract || "");
        setSelectedCompany(response?.data?.userId?.id || "");
        setEndDate(moment(response.data.bonusEndDate).utc().format("YYYY-MM-DD"));
        setSubscriptionType(response.data.subscriptionPack.toString());
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (ByIdDetails) {
      formik.setValues({
        name: ByIdDetails?.name || "",
        description: ByIdDetails?.description || "",
        price: ByIdDetails?.price || "",
        tokens: ByIdDetails?.token || "",
        subscriptionType: subscriptionType || "",
        companyId: ByIdDetails?.companyId || "",
        bonus: ByIdDetails?.bonus || "",
        // category: category || "",
      });
    }
  }, [ByIdDetails]);
  useEffect(() => {
    if (_id) {
      fetchDataById(_id);
    }
  }, [_id]);

  useEffect(() => {
    fetchCompanies();
  }, []);


  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">
            {_id ? `Edit Subscription` : `Add Subscription`}
          </h1>
          <Button
            className="btn btn_primary"
            onClick={() => navigate("/manage-subscription")}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography className="custom_label">
                    Upload Contract documents
                  </Typography>
                  {contract ? (
                    contract.endsWith(".pdf") ? (
                      <div className="upload_image_preview2">
                        <CardMedia
                          component="img"
                          image="/static/images/pdf.png"
                          alt="photo"
                          sx={{ width: 200, height: 200, objectFit: "contain" }}
                          onClick={() => window.open(contract)}
                        />
                        <CancelIcon
                          onClick={() => setContract(null)}
                          color="error"
                        />
                      </div>
                    ) : (
                      <div className="upload_image_preview2">
                        <CardMedia
                          component="img"
                          image={contract}
                          alt="photo"
                          sx={{ width: 200, height: 200 }}
                        />
                        <CancelIcon
                          onClick={() => setContract(null)}
                          color="error"
                        />
                      </div>
                    )
                  ) : (
                    <Box className="upload_image_bnr">
                      <label htmlFor="icon-button-file-vdo">
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file-vdo"
                          type="file"
                          inputProps={{
                            accept: "image/png,image/jpeg,application/pdf",
                          }}
                          onChange={(e: any) => handleImageUpload(e)}
                        />
                        <Button component="span" className="upload_image_btn">
                          <AddIcon />
                        </Button>
                      </label>
                    </Box>
                  )}
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Subscription Name
                  </Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="name"
                    variant="outlined"
                    fullWidth
                    className="text_field"
                    placeholder="Enter Subscription Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={
                      formik.touched.name && formik.errors.name
                        ? formik.errors.name.toString()
                        : ""
                    }
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Price Per Month
                  </Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="price"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Price"
                    value={formik?.values?.price}
                    onChange={(val) => {
                      let { value } = val.target;
                      const numericValue = value.replace(/,/g, '');
                      if (
                        isNumberPrice(numericValue) &&
                        numericValue.length <= 12
                      ) {
                        const formattedValue =
                          formatNumberWithCommas(numericValue);
                        formik.setFieldValue('price', formattedValue);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.price && Boolean(formik.errors?.price)
                    }
                    helperText={
                      formik.touched?.price && formik.errors?.price
                        ? formik.errors?.price.toString()
                        : ""
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    No. of Tokens
                  </Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="tokens"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter no. of tokens"
                    value={formik?.values?.tokens}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.tokens && Boolean(formik.errors?.tokens)
                    }
                    helperText={
                      formik.touched?.tokens && formik.errors?.tokens
                        ? formik.errors?.tokens.toString()
                        : ""
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Bonus (%)
                  </Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="bonus"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 3 }}
                    placeholder="Enter bonus (%)"
                    value={formik?.values?.bonus}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.bonus && Boolean(formik.errors?.bonus)
                    }
                    helperText={
                      formik.touched?.bonus && formik.errors?.bonus
                        ? formik.errors?.bonus.toString()
                        : ""
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Bonus End Date</Typography>
                  <TextField
                    hiddenLabel
                    type="date"
                    name="startDate"
                    variant="outlined"
                    fullWidth
                    placeholder="End Time"
                    className="text_field"
                    value={endDate}
                    onChange={handleDateChange1}
                    InputProps={{
                      endAdornment: (
                        endDate && (
                          <InputAdornment position="end">
                            <IconButton onClick={() => { setEndDateSend(""); setEndDate("") }}>
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      ),
                    }}

                  />
                </Grid>
                {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="custom_label">User Type</Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={category}
                      onChange={handleCategoryChange}
                      onBlur={() => formik.setFieldTouched('category', true)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>

                      <MenuItem value="3">Agent</MenuItem>
                      <MenuItem value="5">Company</MenuItem>
                    </Select>
                    {formik.touched.category && formik.errors.category && (
                      <Typography color="error">{formik.errors.category}</Typography>
                    )}
                  </FormControl>
                </Grid> */}
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="custom_label">
                      Subscription Type
                    </Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={subscriptionType}
                      onChange={handlesubscriptionTypeChange}
                      onBlur={() =>
                        formik.setFieldTouched("subscriptionType", true)
                      }
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>

                      <MenuItem value="1">Monthly</MenuItem>
                      <MenuItem value="3">Quarterly</MenuItem>
                      {/* <MenuItem value="6">Half-Yearly</MenuItem> */}
                      <MenuItem value="12">Yearly</MenuItem>
                    </Select>
                    {formik.touched.subscriptionType &&
                      formik.errors.subscriptionType && (
                        <Typography style={{ fontSize: "12px" }} color="red">
                          {formik.errors.subscriptionType}
                        </Typography>
                      )}
                  </FormControl>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Select Company
                  </Typography>
                  <Autocomplete
                    options={companies || []}
                    getOptionLabel={(option: any) => option?.companyName + " (" + option?.referenceId + ")" || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Company"
                        variant="outlined"
                      />
                    )}
                    value={
                      companies?.find(
                        (it: any) => it?._id === selectedCompany
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      setSelectedCompany(newValue?._id || "");
                    }}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?._id === value?._id
                    }
                  />

                </Grid>
                {/* <Grid item lg={4} md={4} sm={6} xs={12} /> */}
                {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">No. of Agents</Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="agents"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter no. of agents"
                    value={formik?.values?.agents}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.agents && Boolean(formik.errors?.agents)}
                    helperText={
                      formik.touched?.agents && formik.errors?.agents
                        ? formik.errors?.agents.toString()
                        : ""
                    }
                  />
                </Grid>
 

                 <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Description</Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="description"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Description"
                    value={formik?.values?.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.description && Boolean(formik.errors?.description)}
                    helperText={
                      formik.touched?.description && formik.errors?.description
                        ? formik.errors?.description.toString()
                        : ""
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Features</Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="features"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Features"
                    value={formik?.values?.features}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.features && Boolean(formik.errors?.features)}
                    helperText={
                      formik.touched?.features && formik.errors?.features
                        ? formik.errors?.features.toString()
                        : ""
                    }
                  />
                </Grid>
                */}
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Typography className="custom_label">Description</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="description"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={3}
                    placeholder="Enter Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                        ? formik.errors.description.toString()
                        : ""
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>

            <Box className="form_btn">
              <Button
                className="btn btn_primary"
                type="submit"
                disabled={isLoading}
              >
                {_id ? `Update` : `Save`}
              </Button>
            </Box>
          </form>
        </Card>

      </div>
    </div>
  );
};

export default AddSubscription;
